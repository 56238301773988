<template>
<div>
  <el-dialog title="导出预览" :visible.sync="show" :before-close="close" fullscreen>
    <el-table :data="json_data" size="mini" height="calc(100vh - 175px)" border v-loading="loading">
			<el-table-column :label="key" width="150" v-for="(field,key) in json_fields" :key="key">
				<template slot-scope="props" >
					<template v-if="typeof field == 'string'">
						<template v-if="field.split('.').length>1">
							{{props.row.config[field.split('.')[1]]}}
						</template>
						<div v-else>{{props.row[field]}}</div>
					</template>
					<template v-else>
						<div v-if="field.field.split('.').length>1">
							<div v-html="field.callback(props.row.config[field.field.split('.')[1]],props.row)" ></div>
						</div>
						<div v-else>
							<div v-html="field.callback(props.row[field.field],props.row)" ></div>
						</div>
					</template>
				</template>
			</el-table-column>
		</el-table>
		
		<span slot="footer" class="dialog-footer">
			<Excel
			  class=""
			  :data="json_data"
			  :fields="json_fields"
				type="xls"
			  :name="fileName"
			>
			  <el-button type="primary" :loading="loading" >确认导出</el-button>
			</Excel>
			
			
		</span>
   
  </el-dialog>

</div>

</template>

<script>
import Excel from 'vue-json-excel'
export default {
  name: 'ExcelViewer',
  components: {
    Excel
  },
  props: {
    show: Boolean,
		type:{
			default:'',
			type:String
		}
  },
  data(){
    return {
			json_fields:{},
			json_data:[],
			json_meta:[
				{
					key: "charset",
					value: "utf-8",
				},
			],
			fileName:"",
			loading:false,
    }
  },
	mounted(){
		
	},
  watch: {
		show(newData){
			
			if(newData && this.type){
				this.$nextTick(()=>{
					this.initData()
				})
			} else {
				this.json_data = [];
				this.$set(this,'json_fields',{});
			}
		}
  },
	
  methods:{
		
		async initData(){
			this.loading = true;
			let workList = await this.request.get('/api/v1/awardWork/all');
			workList.forEach(work=>{
				this.$root.initAwardType(work);
			});
			let userList = await this.request.get('/api/v1/frontDeskUser/all');
			let userMap = {};
			userList.forEach(item=>{
				if(item.config){
					try{
						item.config = JSON.parse(item.config)
					}catch(e){
						item.config = {};
					}
				}else {
					item.config = {};
				}
				item.workList = [];
				userMap[item.id] = item;
			});
			
			let json_data = [];
			if(this.type=='user'){
				this.fileName = '用户数据报表'+this.$root.format('yyyyMMddHHmmss',new Date())+'.xls';
				json_data = userList;
				
				workList.forEach(work=>{
					let userTarget = json_data.find(user=>user.id == work.userId);
					if(userTarget){
						let url = `${this.$root.getViewUrlHeader()}/?hash=/${this.$root.pk=='EPA'?'Works':'Work'}/Content/${this.$root.md5(work.id)}/${work.id}/0`;
						// return "<div style=\"text-align:left\"><a href=\""+url+"\" target=\"_blank\">"+this.$root.createWID(item||value)+"</a>&nbsp;</div>"
						
						if(this.$root.pk=='EPA'){
							userTarget.workList.push(this.$root.createWID(work));
						} else {
							userTarget.workList.push(`<a href="${url}" target="_blank">${this.$root.createWID(work)}</a></div>`);
						}
						
						// userTarget.workList.push(this.$root.createWID(work));
					}
				});
			}else if(this.type=='judge1' || this.type=='judge2'){
				this.fileName = (this.type=='judge1'?'初评':'终评')+'评审结果报表'+this.$root.format('yyyyMMddHHmmss',new Date())+'.xls';
				json_data = workList.filter(work=>{
					if(this.type=='judge1'){
						return work.awardWorkStatus == 11
					} else {
						return work.awardWorkStatus == 21
					}
				});//.sort((a,b)=>a.id-b.id);
				json_data.forEach(item=>{
					if(item.content){
						try{
							item.config = JSON.parse(item.content)
						}catch(e){
							item.config = {};
						}
					}else {
						item.config = {};
					}
					item.userData = userMap[item.userId]?userMap[item.userId]:{};
				});
				
				// if(this.type=='judge1'){
				// 	json_data = userList.filter(user=>user.role=='JUDGES'&&user.config.groupType != 'S')
				// 	json_data.forEach(item=>{
				// 		item.
				// 	})
				// }else {
				// 	json_data = userList.filter(user=>user.role=='JUDGES')
				// }
				
			} else if(this.type=='work'){
				this.fileName = '作品数据报表'+this.$root.format('yyyyMMddHHmmss',new Date())+'.xls';
				json_data = workList;
				workList.forEach(item=>{
					if(item.content){
						try{
							item.config = JSON.parse(item.content)
						}catch(e){
							item.config = {};
						}
					}else {
						item.config = {};
					}
					item.userData = userMap[item.userId]?userMap[item.userId]:{};
				});
				
			}
			this.$set(this,'json_data',json_data);
			this[this.type+'_'+this.$root.pk](userList);
			
			this.loading = false;
		},
		judge1_APD(allUser){
			let userList = allUser.filter(user=>user.role=='JUDGES'&&user.config.groupType != 'S');
			let json_fields = {
				'作品编号':{
					field:'',
					callback:(value,item)=>{
						let target = item || value;
						let url = `${this.$root.getViewUrlHeader()}/?hash=/Work/Content/${this.$root.md5(target.id)}/${target.id}/0`;
						// let url = `http://localhost:8081/?hash=/Work/Content/${this.$root.md5(target.id)}/${target.id}/0`;
						return "<div style=\"text-align:center\"><a href=\""+url+"\" target=\"_blank\">"+this.$root.createWID(item||value)+"</a>&nbsp;</div>"
					}
				},
				'参赛类别':'awardTypeName',
				'作品名称':{
					field:'config.title',
					callback:(value)=>{
						return "<div style=\"text-align:left\">"+value+"</div>"
					}
				},
			};
			let workMap = {},hasRecommentMap = {},scoreMap = {};
			
			userList.forEach(user=>{
				
				user.vname = user.email.split("@")[0];
				workMap[user.vname] = {r:{},s:{}};
				if(user.config.firstScoreResult){
					user.config.firstScoreResult.forEach(res=>{
						if(res.recommentList){
							res.recommentList.forEach(r=>{
								workMap[user.vname].r[r] = true;
								if(!hasRecommentMap[r]){hasRecommentMap[r] = [];}
								hasRecommentMap[r].push(user.vname);
							});
							
						}
						if(res.scoreList){
							res.scoreList.forEach(s=>{
								workMap[user.vname].s[s.wid] = s.score;
								if(!scoreMap[s.wid]){scoreMap[s.wid] = 0;}
								scoreMap[s.wid] += s.score;
							});
						}
					})
				}
			})
			
			userList = userList.splice(userList.findIndex(item=>item.vname == 'br'),1).concat(userList);
			
			json_fields['推荐票数'] = {
				field:'',
				callback:(value,item)=>{
					let target = item || value;
					return `<div style="text-align:center">${hasRecommentMap[target.id]?hasRecommentMap[target.id].length:0}</div>`
				}
			}
			json_fields['推荐人'] = {
				field:'',
				callback:(value,item)=>{
					let target = item || value;
					return `<div style="text-align:center">${hasRecommentMap[target.id]?('<p>'+(hasRecommentMap[target.id].join(' & '))+'</p>'):''}</div>`
				}
			}
			json_fields['总分(不含推荐)'] = {
				field:'',
				callback:(value,item)=>{
					let target = item || value;
					return `<div style="text-align:center">${scoreMap[target.id]?scoreMap[target.id]:0}</div>`
				}
			}
			
			userList.forEach(user=>{
				json_fields[user.email.split("@")[0]] = {
					field:'',
					callback:(value,item)=>{
						let target = item || value;
						return `<div style="text-align:center">${workMap[user.vname].r[target.id]?'★':(typeof workMap[user.vname].s[target.id] !='undefined'?workMap[user.vname].s[target.id]:'')}</div>`
					}
				}
			})
			
			let json_data = this.json_data.sort((a,b)=>{
				if(hasRecommentMap[a.id] && !hasRecommentMap[b.id]){
					return -1;
				} else if(!hasRecommentMap[a.id] && hasRecommentMap[b.id]){
					return 1;
				} else if(hasRecommentMap[a.id] && hasRecommentMap[b.id]){
					if(hasRecommentMap[a.id].length == hasRecommentMap[b.id].length){
						return (scoreMap[b.id]||0)-(scoreMap[a.id]||0)
					} else {
						return hasRecommentMap[b.id].length - hasRecommentMap[a.id].length
					}
					
				} else {
					return (scoreMap[b.id]||0)-(scoreMap[a.id]||0)
				}
			});
			this.$set(this,'json_data',json_data);
			this.$set(this,'json_fields',json_fields);
		},
		judge2_APD(allUser){
			let userList = allUser.filter(user=>user.role=='JUDGES');
			
			let json_fields = {
				'作品编号':{
					field:'',
					callback:(value,item)=>{
						let target = item || value;
						let url = `${this.$root.getViewUrlHeader()}/?hash=/Work/Content/${this.$root.md5(target.id)}/${target.id}/0`;
						// let url = `http://localhost:8081/?hash=/Work/Content/${this.$root.md5(target.id)}/${target.id}/0`;
						return "<div style=\"text-align:center\"><a href=\""+url+"\" target=\"_blank\">"+this.$root.createWID(item||value)+"</a>&nbsp;</div>"
					}
				},
				'参赛类别':'awardTypeName',
				'作品名称':{
					field:'config.title',
					callback:(value)=>{
						return "<div style=\"text-align:left\">"+value+"</div>"
					}
				},
			};
			let workMap = {},voteMap = {};
			
			userList.forEach(user=>{
				
				user.vname = user.email.split("@")[0];
				workMap[user.vname] = {};
				let secondScoreResult = (user.config.secondScoreResult||[]).find(item=>item.aid==0);
				let votedList = [];
				if(secondScoreResult){
					votedList = secondScoreResult.votedList;
				}
				// console.log(user.vname,secondScoreResult,votedList)
				votedList.forEach(res=>{
					workMap[user.vname][res.wid] = res.voteType;
					if(!voteMap[res.wid]){voteMap[res.wid] = 0;}
					if(res.voteType == 1){
						voteMap[res.wid] ++;
					}
				})
			})
			userList = userList.splice(userList.findIndex(item=>item.vname == 'br'),1).concat(userList);
			
			
			json_fields['总票数'] = {
				field:'',
				callback:(value,item)=>{
					let target = item || value;
					return `<div style="text-align:center">${voteMap[target.id]?voteMap[target.id]:0}</div>`
				}
			}
			
			userList.forEach(user=>{
				json_fields[user.email.split("@")[0]] = {
					field:'',
					callback:(value,item)=>{
						let target = item || value;
						return `<div style="text-align:center">${workMap[user.vname][target.id]==1?'★':(workMap[user.vname][target.id]==-1?'':'')}</div>`
					}
				}
			})
			
			let json_data = this.json_data.sort((a,b)=>{
				return (voteMap[b.id]||0)-(voteMap[a.id]||0)
			});
			this.$set(this,'json_data',json_data);
			this.$set(this,'json_fields',json_fields);
		},
		user_APD(){
			
			let unitList = {
				'设计师个人投稿':{name:"设计师个人姓名"},
				'设计师组合投稿':{name:"设计师组合主要成员姓名"},
				'设计工作室/公司投稿':{name:"设计工作室/公司名称"},
				'品牌方投稿':{name:"品牌方名称",team:"设计团队名称"},
			}
			let json_fields = {
				'投稿者编号':{
					field:'id',
					callback:(value)=>{
						return "<div style=\"text-align:center\">"+this.$root.createUID(value)+"&nbsp;</div>"
					}
				},
				'注册邮箱':'email',
				'注册时间':{
					field:'createTime',
					callback:(value)=>{
						return "<div style=\"text-align:center\">"+value+"</div>"
					}
				},
				'投稿单位':'config.unitType',
				'投稿单位名称/成员':'config.unitName',
				'设计团队名称':'config.unitTeam',
				'所在城市':'config.city',
				'通信地址':'config.address',
				'联系邮箱':'config.email',
				'联系电话':{
					field:'config.tel',
					callback:(value)=>{
						return "<div style=\"text-align:center\">"+value+"</div>"
					}
				},
				'微信':{
					field:'config.wechat',
					callback:(value)=>{
						return "<div style=\"text-align:left\">"+value+"</div>"
					}
				},
				'官网':{
					field:'config.website',
					callback:(value)=>{
						return "<div style=\"text-align:left\">"+value+"</div>"
					}
				},
				'作品ID列表':{
					field:'workList',
					callback:(value)=>{
						if(value){
							return "<div style=\"text-align:left\">"+value.join("<br/>")+"</div>";
						}
						return ""
					}
				}
				
			};
			
			this.$set(this,'json_fields',json_fields);
		},
		
		work_APD(){
			let json_fields = {
				'作品编号':{
					field:'',
					callback:(value,item)=>{
						return "<div style=\"text-align:center\">"+this.$root.createWID(item||value)+"&nbsp;</div>"
					}
				},
				'投稿时间':{
					field:'createTime',
					callback:(value,item)=>{
						return "<div style=\"text-align:center\">"+value+"</div>"
					}
				},
				'参赛类别':'awardTypeName',
				'作品名称':{
					field:'config.title',
					callback:(value)=>{
						return "<div style=\"text-align:left\">"+value+"</div>"
					}
				},
				'创作年份':{
					field:'config.year',
					callback:(value)=>{
						return "<div style=\"text-align:center\">"+value+"</div>"
					}
				},
				'投稿者编号':{
					field:'userId',
					callback:(value)=>{
						return "<div style=\"text-align:center\">"+this.$root.createUID(value)+"&nbsp;</div>"
					}
				},
				'主创人员名单':{
					field:'config.creators',
					callback:(value)=>{
						return "<div style=\"text-align:left\">"+value.map(item=>item.key+':'+item.value).join("<br/>")+"&nbsp;</div>"
					}
				},
				'项目介绍':'config.description',
				'作品图片数量':{
					field:'config.images',
					callback:(value)=>{
						return "<div style=\"text-align:center\">&nbsp;"+value.length+"&nbsp;</div>"
					}
				},
				'作品是否附带视频':{
					field:'config.videos',
					callback:(value)=>{
						return "<div style=\"text-align:center\">&nbsp;"+(value && value.length >0 ? '是':'否')+"&nbsp;</div>"
					}
				},
				'作品状态':{
					field:'awardWorkStatus',
					callback:(value)=>{
						return "<div style=\"text-align:left\">"+(this.$root.getAwardWorkStatus(value)).aname+"</div>"
					}
				},
				'获得奖项':{
					field:'groupType',
					callback:(value)=>{
						return "<div style=\"text-align:left\">"+(this.$root.getGroupType(value)).aname+"</div>"
					}
				}
				
			};
			
			this.$set(this,'json_fields',json_fields);
		},
		
		user_EPA(){
			
			let json_fields = {
				'投稿者编号':{
					field:'id',
					callback:(value)=>{
						return "<div style=\"text-align:center\">"+this.$root.createUID(value)+"&nbsp;</div>"
					}
				},
				'注册邮箱':'email',
				'注册时间':{
					field:'createTime',
					callback:(value)=>{
						return "<div style=\"text-align:center\">"+value+"</div>"
					}
				},
				'参赛组别':{
					field:'config.unitType',
					callback:(value)=>{
						let unitType = '';
						if(value==1) unitType = '非专业组';
						else if(value==2) unitType = '专业组';
						return "<div style=\"text-align:center\">"+unitType+"</div>"
					}
				},
				'参赛单位':{
					field:'config.groupType',
					callback:(value)=>{
						let groupType = '';
						if(value==1) groupType = '个人参赛';
						else if(value==2) groupType = '团队参赛';
						return "<div style=\"text-align:center\">"+groupType+"</div>"
					}
				},
				'联系电话':{
					field:'config.tel',
					callback:(value)=>{
						return "<div style=\"text-align:center\">"+value?value:''+"</div>"
					}
				},
				'成员':{
					field:'config.memberData',
					callback:(value)=>{
						let strs = [];
						if(value){
							value.forEach((item,index)=>{
								let str = '成员 '+(index+1) + '<br>';
								str +='姓名:'+item.name + '<br>';
								str +='院校:'+item.school + '<br>';
								str +='专业:'+item.major;
								// str +='<img src="'+item.studentCard+'" />' + '<br>';
								strs.push(str)
							});
						}
						return "<div style=\"text-align:left\">"+strs.join("<br/>----------------<br/>")+"</div>"
					}
				},
				'作品ID列表':{
					field:'workList',
					callback:(value)=>{
						let list = [];
						if(value){
							// list = value.map(item=>{
							// 	let id = this.$root.rebackWID(item);
							// 	let sid = this.$root.md5(id);
							// 	return `<a href="http://design.zhgxfz.com/#/Works/Content/${sid}/${id}/0" target="_blank">${item}</a>`;
							// })
							
							return "<div style=\"text-align:left\">"+value.join("<br/>")+"</div>";
							// return "<div style=\"text-align:left\">"+list.join("<br/>")+"</div>";
						}
						return ""
					}
				}
				
			};
			
			this.$set(this,'json_fields',json_fields);
		},
		
		work_EPA(){
			let json_fields = {
				'作品编号':{
					field:'',
					callback:(value,item)=>{
						// let target = item || value;
						// let url = `${this.$root.getViewUrlHeader()}/?hash=/Works/Content/${this.$root.md5(target.id)}/${target.id}/0`;
						// let url = `http://localhost:8081/?hash=/Work/Content/${this.$root.md5(target.id)}/${target.id}/0`;
						// return "<div style=\"text-align:center\"><a href=\""+url+"\" target=\"_blank\">"+this.$root.createWID(item||value)+"</a>&nbsp;</div>"
						
						return "<div style=\"text-align:center\">"+this.$root.createWID(item||value)+"&nbsp;</div>"
					}
				},
				'投稿时间':{
					field:'createTime',
					callback:(value,item)=>{
						return "<div style=\"text-align:center\">"+value+"</div>"
					}
				},
				'参赛类别':'awardTypeName',
				'参赛组别':{
					field:'unitType',
					callback:(value)=>{
						let unitType = '';
						if(value==1) unitType = '非专业组';
						else if(value==2) unitType = '专业组';
						return "<div style=\"text-align:center\">"+unitType+"</div>"
					}
				},
				'作品名称':{
					field:'config.title',
					callback:(value)=>{
						return "<div style=\"text-align:left\">"+value+"</div>"
					}
				},
				'作者':'config.creators',
				'投稿者编号':{
					field:'userId',
					callback:(value)=>{
						return "<div style=\"text-align:center\">"+this.$root.createUID(value)+"&nbsp;</div>"
					}
				},
				'项目介绍':'config.description',
				'作品图片数量':{
					field:'config.images',
					callback:(value)=>{
						return "<div style=\"text-align:center\">&nbsp;"+value.length+"&nbsp;</div>"
					}
				},
				'作品是否附带视频':{
					field:'config.video',
					callback:(value)=>{
						return "<div style=\"text-align:center\">&nbsp;"+(value && value.path ? '是':'否')+"&nbsp;</div>"
					}
				},
				'作品状态':{
					field:'awardWorkStatus',
					callback:(value)=>{
						return "<div style=\"text-align:left\">"+(this.$root.getAwardWorkStatus(value)).aname+"</div>"
					}
				},
				'网络投票数':{
					field:'voteCount',
					callback:(value)=>{
						return "<div style=\"text-align:center\">&nbsp;"+value+"&nbsp;</div>"
					}
				},
				
			};
			
			this.$set(this,'json_fields',json_fields);
		},
		
		
    close(){
      this.$emit('update:show',false);
    },
    
  },
}
</script>

<style scoped lang="less">

</style>
