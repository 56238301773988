<template>
  <div>

    <div class="search-box mt-10">
			<div class="flex between">
				<div>
					<el-form :inline="true" :model="formData" class="user-form-inline" size="mini">
					  <el-form-item label="">
					    <el-input 
					    v-model="formData.keywords"
					    clearable :readonly="isSearching"
					    placeholder="请输入邮箱 可模糊查询" 
					    style="width:220px"
					    @keyup.enter.native="doSearch"
					    @clear="doReset"
					    @blur="doBlur"></el-input>
					  </el-form-item>
						<!-- <template v-if="$root.pk=='APD'">
							<el-form-item label="用户角色">
								<el-select 
									v-model="formData.role" 
									placeholder="请选择" 
									style="width:100px"
									clearable @clear="doReset" @change="doSearch"
									>
									<el-option label="全部" value=""></el-option>
									<el-option label="普通用户" value="NORMAL"></el-option>
									<el-option label="评委" value="JUDGES"></el-option>
								</el-select>
							</el-form-item>
						</template> -->
					  <el-form-item>
					    <el-button type="primary" icon="el-icon-search" @click="doSearch" :loading="isSearching">查询</el-button>
							<el-button type="success" size="mini" icon="el-icon-download" @click="doExport" :loading="isExporting" v-if="$root.pk=='EPA'">导出数据包</el-button>
							<el-button type="success" size="mini" icon="el-icon-s-data" @click="excelViewerType='user';showExcelViewer=true" >导出用户报表</el-button>
							
						
							<el-button v-if="$root.pk=='APD'" type="warning" size="mini" icon="el-icon-medal-1" @click="excelViewerType='judge1';showExcelViewer=true" >导出初评报表</el-button>
							<el-button v-if="$root.pk=='APD'" type="warning" size="mini" icon="el-icon-medal-1" @click="excelViewerType='judge2';showExcelViewer=true" >导出终评报表</el-button>
							
					  </el-form-item>
					</el-form>
				</div>
				<div>
					<div><el-button type="success" icon="el-icon-circle-plus-outline" @click="doAdd" >新增</el-button></div>
				</div>
			</div>
			
      

    </div>
    <el-divider></el-divider>
    <div>
      <el-table
        :data="list"
        v-loading="isSearching"
        style="width: 100%"
        row-key="id"
        border
        size="mini">
				<!-- <el-table-column
					prop="id"
					label="ID"
					width="80"
					align="center"
				></el-table-column> -->
				<el-table-column
					prop="id"
					label="参赛编号"
					width="80"
					align="center"
				>
					<template slot-scope="props" >
					{{$root.createUID(props.row.id)}}
					</template>
				</el-table-column>
				
        <el-table-column
          prop="email"
          label="用户邮箱"
        ></el-table-column>
				<el-table-column
          prop="createTime"
          label="创建时间"
          width="140"
          align="center"
        >
					<template slot-scope="props" >
						<!-- <template v-if="props.row.createTime">{{$root.format('yyyy-MM-dd HH:mm:ss',new Date(props.row.createTime))}}</template>
						<template v-else> - </template> -->
						{{props.row.createTime}}
					</template>
				</el-table-column>
				<el-table-column
				  prop="config"
				  label="是否填写参赛信息"
				  width="100"
				  align="center"
				>
				   <template slot-scope="props">
						 <template v-if="$root.pk=='APD'">
							 <el-tag type="success" size="mini" v-if="props.row.config && props.row.config.city">是</el-tag>
							 <el-tag type="info" size="mini" v-else>否</el-tag>
						 </template>
						 <template v-if="$root.pk=='EPA'">
							 <el-tag type="success" size="mini" v-if="props.row.config && props.row.config.tel">是</el-tag>
							 <el-tag type="info" size="mini" v-else>否</el-tag>
						 </template>
				     
				   </template>
				</el-table-column>
        <el-table-column
          prop="role"
          label="类型"
          width="100"
          align="center"
        >
           <template slot-scope="props">
             <el-tag type="info" size="mini" effect="dark" v-if="props.row.role=='NORMAL'">普通用户</el-tag>
             <el-tag type="success" size="mini" effect="dark" v-if="props.row.role=='JUDGES'">评委</el-tag>
						 <div v-if="props.row.role=='JUDGES' && props.row.config && props.row.config.groupType">
							 <template v-if="props.row.config.groupType=='A'"><el-tag type="warning" size="mini" effect="dark" >编委A组</el-tag></template>
							 <template v-if="props.row.config.groupType=='B'"><el-tag type="warning" size="mini" effect="dark" >编委B组</el-tag></template>
							 <template v-if="props.row.config.groupType=='C'"><el-tag type="warning" size="mini" effect="dark" >编委C组</el-tag></template>
							 <template v-if="props.row.config.groupType=='S'"><el-tag type="warning" size="mini" effect="dark" >学术顾问</el-tag></template>
						 </div>
           </template>
        </el-table-column>
        <el-table-column 
          label="操作" 
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="doEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="doDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-divider></el-divider>
      <div class="ta-r">
        <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next"
          @current-change="doChangePage"
          :page-size="pagination.size"
          :current-page="pagination.page"
          :total="total">
        </el-pagination>
      </div>

    </div>
		<UserEdit :data="currentData" :show.sync="showFormEdit" :isEdit="isEdit"/>
		<ExcelViewer :type="excelViewerType" :show.sync="showExcelViewer"/>
  </div>
</template>

<script>
import UserEdit from '@/components/UserEdit';
import ExcelViewer from '@/components/ExcelViewer';

export default {
  name: 'User-List',
  components: {
    UserEdit,
		ExcelViewer
  },
  data(){
    
    return {
      formData:{
        keywords:"",
				role:""
      },
			isEdit:false,
      pagination:{
        pageSize:10,
        page:1,
      },
      total:0,
      list:[],
      isSearching:false,
      isExporting:false,
      showFormEdit:false,
      showExcelViewer:false,
      currentData:{},
			excelViewerType:'user',
    };
  },
  watch: {
    showFormEdit(newData){
      if(!newData){
        this.doSearch(false);
      }
    }
  },
  mounted () {
		// this.request.zd.create('NEWS',{total:0,list:[]});
    this.doSearch();
  },
  methods: {
		doAdd(){
			this.currentData = {};
			this.isEdit = false;
			this.showFormEdit = true;
		},
    doEdit(index,row){
      console.log(row);
      this.currentData = this.$root.copy(row);
			this.isEdit = true;
      this.showFormEdit = true;
    },
    doDelete(index,row){
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.request.delete(`/api/v1/frontDeskUser/delete?id=${row.id}`).then(res=>{

          if(!res){return ;}
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.doSearch(false);
        })
        
      }).catch(() => {
                
      });
      
    },
		async doExport(){
			this.$confirm('此操作将会在服务端进行打包压缩等操作,请留意在非高峰期间操作.', '提示', {
				confirmButtonText: '确定,导出',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				var formData = this.$root.copy(this.formData);
				var params = this.$root.object2urlparams(formData);
				this.isExporting = true;
				await this.request.get(`/api/v1/exportTask/exportFrontDeskUser?${params}`);
				this.isExporting = false;
				this.$root.$emit('openExportList');
			}).catch(() => {
				        
			});
		},

    async getList(url){
      this.isSearching = true;
			this.list = [];
			this.total = 0;
			this.pagination.page = 1;
			var result = await this.request.get(url);
			if(result){
				this.list = result.rows || [];
				this.total = result.total || 0;
				this.pagination.page = result.page || 1;
			}
			this.isSearching = false;
			this.list.forEach(item=>{
				if(item.config){
					try{
						item.config = JSON.parse(item.config)
					}catch(e){
						item.config = {};
					}
				}else {
					item.config = {};
				}
			})
			return this.list;
			
   //    this.request.get(url).then(res=>{
   //      this.isSearching = false;
   //      this.list = [];
   //      this.total = 0;
   //      this.pagination.page = 1;
   //      if(!res){return ;}
   //      this.list = res.results || [];
   //      // this.list.map(d=>{
   //      //   d.resources.unshift({type:"IMAGE",url:d.thumbnailUrl});
   //      // })
   //      this.total = res.total;
   //      // this.total = 646;
   //      this.pagination.page = res.page;

   //    });
    },
    doSearch(resetPage = true){
      // if(!this.formData.collectUserName){
      //   this.$message.warning("搜索内容不能为空");
      //   return ;
      // }
      

      var formData = {...this.pagination,...this.formData};
      if(resetPage){formData.page = 1;}
      var params = this.$root.object2urlparams(formData);


      this.getList(`/api/v1/frontDeskUser/paging?${params}`);
      
    },
    doChangePage(currentPage){
      var formData = {...this.pagination,...this.formData};
      formData.page = currentPage;
      var params = this.$root.object2urlparams(formData);
      this.getList(`/api/v1/frontDeskUser/paging?${params}`);
    },
    doBlur(){
      // if(!this.formData.collectUserName){this.doReset();}
    },
    doReset(){
      this.list = [];
      this.total = 0;
      this.pagination.page = 1;
      this.doSearch();
      // this.formData.collectType = 'INS';
    },
  }
}
</script>
<style lang="less" scoped>
.user-form-inline{
  .search-box{
    .el-select .el-input{
      width:160px;
    }
  }
}
</style>