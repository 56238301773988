const md5 = require("blueimp-md5")
const Minio = require('minio')
const OSS = require('ali-oss');
const stream = require('stream')
var ossClient,ossConfig;

const Common = {
  data(){
    return {
			pk: process.env.VUE_APP_PK,
			awardWorkStatusList:[],
			awardTypeList:[],
			awardStageList:[],
			creatorList:[],
			groupTypeList:[],
			CONFIG:{},
			lang:'CN'
    }
  },
  async created(){
    if(!ossClient){this.initOSS();}
		// await this.request.zd.delete("awardTypeList");
		await this.initData();
		this.$mount('#app');
		console.log('created');
		// this.createWork();
  },
  methods:{
		showViewer(list,index){
			var app = this.$root.$children[0];
			app.$refs.viewer.showViewer(list,index);
		},
		createWork(){
			let awardType = 2; //1 海报 2创意视频类 3小品
			this.request.post(`/api/v1/awardWork/manage/create`,{
				  "awardStage": 1,
				  "awardType": awardType, 
				  "awardWorkStatus": 1,
				  "content": "{\"title\":\"初始化标题\",\"creators\":\"作者1,作者2\",\"awardType\":"+awardType+",\"description\":\"作品描述...\",\"images\":[],\"video\":\"\",\"idea\":{},\"statement\":{}}",
				  "groupType": 0,
				  "unitType": 2, // 1非专业 2专业
				  "userId": 468
			})
		},
		md5(text){
			return md5(text);
		},
		async initZD(key,defaultDataAPD=[],defaultDataEPA=[]){
			var list = await this.request.zd.get(key);
			if(!list && !list.length ){
				list = [];
				if(this.pk == 'APD'){
					list = defaultDataAPD;
				} else if(this.pk == 'EPA'){
					list = defaultDataEPA;
				}
				await this.request.zd.create(key,list);
			}
			
			this.$set(this,key,list||[]);
			console.log(key,list||[]);
		},
		async initData(){
			// await this.request.zd.delete("CONFIG")
			await this.initZD('CONFIG',{
				"index":{
					"slider":[
						{
							"id":"b725bdbbf992aa2b3a304ab3e432608a",
							"linkUrl":"https://www.baidu.com",
							"path":"http://apd-resource.oss-cn-shenzhen.aliyuncs.com/upload-files/others/cover/03e3b4369095e661fcba935bd1da68ed.jpg",
							"cover":"http://apd-resource.oss-cn-shenzhen.aliyuncs.com/upload-files/others/cover/80d9a6b6b28b2325cf0202ff4a4a26ba.jpg",
						},
						{
							"id":"21f62f643fd7aae03e06f6ef135fb3bc",
							"linkUrl":"",
							"path":"http://apd-resource.oss-cn-shenzhen.aliyuncs.com/upload-files/others/cover/dc97cc7e51e24aa8fc2a9b6e5e7f4f87.jpg",
							"cover":"http://apd-resource.oss-cn-shenzhen.aliyuncs.com/upload-files/others/cover/6f8ad58c71108f95a2330ff5910b543f.jpg",
						},
						{
							"id":"6bb7f3dd42901dae84b3439f9c8b23d2",
							"linkUrl":"",
							"path":"http://apd-resource.oss-cn-shenzhen.aliyuncs.com/upload-files/others/cover/c4f6fb8ba064fbbc01f9bee56c72483c.jpg",
							"cover":"http://apd-resource.oss-cn-shenzhen.aliyuncs.com/upload-files/others/cover/a14f8272ce3bc62f74276181a97aa92f.jpg",
						},
					],
				},
				"mainAward":18,
				"onRegister":true,
				"onSubmit":true,
				"yearList":[]
			},{
				mainAward: 1,
				onRegister: true,
				onSubmit: false,
				index:{
					PC:{id:this.$root.createId(),linkUrl:"",path:"http://design.zhgxfz.com/upload-resource/upload-files/others/cover/acc6fb197cb8211ba32d635a91424e9b.png"},
					H5:{id:this.$root.createId(),linkUrl:"",path:"http://design.zhgxfz.com/upload-resource/upload-files/others/cover/61920b1c6573675a5388982c3a47e1d2.png"},
				},
				intro:{
					PC:[{id:this.$root.createId(),linkUrl:"",path:"http://design.zhgxfz.com/upload-resource/upload-files/others/cover/c5adb59c7df1036da015073a081d0435.jpg"}],
					H5:[
						{id:this.$root.createId(),linkUrl:"",path:"http://design.zhgxfz.com/upload-resource/upload-files/others/cover/06d9f3a0c3925f9f2bf01130c6083cdd.jpg"},
						{id:this.$root.createId(),linkUrl:"",path:"http://design.zhgxfz.com/upload-resource/upload-files/others/cover/7b1ef37be3df18fae544044572645d88.jpg"},
					],
				}
			});
			// await this.request.zd.delete("awardWorkStatusList")
			await this.initZD('awardWorkStatusList',
				[
					{status:1,name:"已提交",aname:"已提交"},
					{status:5,name:"已提交",aname:"待处理"},
					{status:11,name:"已提交",aname:"已入选初评"},
					{status:21,name:"已提交",aname:"已入选终评"},
				],
				[
					{status:1,name:"报名成功",aname:"报名成功"},
					{status:9,name:"报名成功",aname:"未通过初筛"},
					{status:11,name:"报名成功",aname:"通过初筛"},
					{status:21,name:"报名成功",aname:"评审中"},
					{status:31,name:"报名成功",aname:"未获奖"},
					{status:32,name:"报名成功",aname:"已获奖"},
					
				]
			);
			// await this.request.zd.delete("groupTypeList")
			await this.initZD('groupTypeList',
				[
					{status:1,name:"待揭晓",aname:"入选年度收录单元"},
					{status:2,name:"待揭晓",aname:"入选年度推荐单元"},
					{status:3,name:"待揭晓",aname:"入选年度主题单元"},
				],
				[
					{status:1,name:"先锋作品奖",aname:"先锋作品奖"},
					{status:2,name:"最佳作品奖",aname:"最佳作品奖"},
					{status:3,name:"优秀作品奖",aname:"优秀作品奖"},
					{status:4,name:"最佳人气奖",aname:"最佳人气奖"},
				]
			);
			// await this.request.zd.delete("awardTypeList")
			await this.initZD('awardTypeList',
				[
					// A组（5个编委）：品牌（BR）、环境（EG）、包装（PD）、插画（CI）、标志（LD）
					// B组（4个编委）：文字（TD）、出版（PR）、海报（PO）、数字（DD）
					{"id":1,"code":"A","groupType":"A","ename":"BRANDING","name":"品牌设计","config":{"video":false}},
					{"id":2,"code":"B","groupType":"A","ename":"ENVIRONMENTAL GRAPHICS","name":"环境图形","config":{"video":false}},
					{"id":3,"code":"C","groupType":"B","ename":"TYPE DESIGN","name":"文字设计","config":{"video":true}},
					{"id":4,"code":"D","groupType":"B","ename":"PRINT","name":"出版物及印刷品设计","config":{"video":false}},
					{"id":5,"code":"E","groupType":"B","ename":"POSTER DESIGN","name":"海报设计","config":{"video":true}},
					{"id":6,"code":"F","groupType":"A","ename":"PACKAGE DESIGN","name":"包装设计","config":{"video":false}},
					{"id":7,"code":"G","groupType":"A","ename":"COMMERCIAL ILLUSTRATION","name":"商业插画","config":{"video":false}},
					{"id":8,"code":"H","groupType":"B","ename":"DIGITAL DESIGN","name":"数字设计","config":{"video":true}},
					{"id":9,"code":"I","groupType":"A","ename":"LOGO DESIGN","name":"标志设计","config":{"video":false}},
				],
				
				[
					{id:1,code:"A",name:"海报设计类",description:"<p>1. 作品要求：平面海报、插画海报、C4D海报、漫画海报等形式，系列作品不超过5幅；</p><p>2. 上传要求：作品文件(JPG/JPEG)、完整的创作说明文件(PDF)、原创声明。</p>", config:{video:false,image:true}},
					{id:2,code:"B",name:"创意视频类",description:"<p>1. 作品要求：影视视频、微电影、短视频、动画片等形式，不超过5mins；</p><p>2. 上传要求：作品文件(MP4/MOV)、完整的创作说明文件(PDF))、原创声明。</p>",config:{video:true,image:true}},
					{id:3,code:"C",name:"小品装置类",description:"<p>1. 作品要求：景观小品、应用装置、艺术装置等形式；</p><p>2. 上传要求：不少于5张作品设计效果图(JPG/JPEG)、完整的创作说明文件(PDF))、原创声明。</p>",config:{video:false,image:true}},
				]
			);

			var awardStageList = (new Array(18)).fill({});
			awardStageList = awardStageList.map(function(item,index){return {
				id:awardStageList.length-index,
				name:"APD "+(awardStageList.length-index),
				ename:"APD "+(awardStageList.length-index),
			}})
			
			await this.initZD('awardStageList',awardStageList,
				[
					{id:1,name:"2022",ename:"2022"},
				]
			);
			// await this.request.zd.delete("creatorList");
			await this.initZD('creatorList',
				[
					{"code":"DA","name":"设计公司",ename:"Design Agency(ies)"},
					{"code":"CD","name":"创意指导",ename:"Creative Director(s)"},
					{"code":"AD","name":"艺术指导",ename:"Art Director(s)"},
					{"code":"DD","name":"设计指导",ename:"Design Director(s)"},
					{"code":"DS","name":"设计师",ename:"Designer(s)"},
					{"code":"AT","name":"建筑师",ename:"Architect(s)"},
					{"code":"ID","name":"室内设计师",ename:"Interior Designer(s)"},
					{"code":"MD","name":"动态设计师",ename:"Motion Designer(s)"},
					{"code":"WD","name":"网页设计师",ename:"Website Designer(s)"},
					{"code":"TG","name":"字体设计师",ename:"Typographer(s)"},
					{"code":"CW","name":"文案",ename:"Copywriter(s)"},
					{"code":"ST","name":"策略师",ename:"Strategist(s)"},
					{"code":"SL","name":"造型师",ename:"Stylist(s)"},
					{"code":"AR","name":"艺术家",ename:"Artist(s)"},
					{"code":"IR","name":"插画师",ename:"Illustrator(s)"},
					{"code":"PM","name":"项目经理",ename:"Project Manager(s)"},
					{"code":"PR","name":"制作",ename:"Producer(s)"},
					{"code":"DE","name":"开发",ename:"Developer(s)"},
					{"code":"CO","name":"统筹",ename:"Coordinator(s)"},
					{"code":"PG","name":"摄影",ename:"Photographer(s)"},
					{"code":"CL","name":"客户",ename:"Client(s)"},
				],
				[]
			);
		},
		initAwardType(work){
			var target = this.awardTypeList.find(item=>item.id == work.awardType);
			if(target){
				work.awardTypeCode = target.code;
				work.awardTypeName = target.name;
				work.awardTypeEname = target.ename;
			} else {
				work.awardTypeCode = "";
				work.awardTypeName = "";
			}
			return target;
		},
		getAwardWorkStatus(status){
			var target = this.awardWorkStatusList.find(item => item.status == status);
			if(target){return target}
			else return {status,name:"未知状态",aname:"未知状态"};
		},
		getGroupType(status){
			var target = this.groupTypeList.find(item => item.status == status);
			if(target){return target}
			else return {status,name:"未设奖项",aname:"未设奖项"};
		},
		createUID(id){
			// console.log("createUID",id.toString(17).toUpperCase());
			if(this.pk == 'EPA'){
				return this.prefixZero(id,8);
			}
			return (id*10000).toString(17).toUpperCase();
		},
		createWID(item){
			return item.awardTypeCode + this.prefixZero(item.awardStage,2) + this.prefixZero(item.id,6);
		},
		getViewUrlHeader(){
			if(this.pk=='APD'){
				if(process.env.NODE_ENV === 'production'){
					return "https://apd-design.cn";
				} else if(process.env.NODE_ENV === 'development'){
					return "http://localhost:8081";
				}
			} else if(this.pk=='EPA'){
				if(process.env.NODE_ENV === 'production'){
					return "http://design.zhgxfz.com";
				} else if(process.env.NODE_ENV === 'development'){
					return "http://epa-web.sizne.net";
				}
			}
		},
		doWorkView(row){
			var sid = this.md5(row.id);
			var urlHeader = this.getViewUrlHeader();
			if(this.pk=='APD'){
				window.open(urlHeader+"/#/Work/Content/"+sid+"/"+row.id+"/0")
			} else if(this.pk=='EPA'){
				window.open(urlHeader+"/#/Works/Content/"+sid+"/"+row.id+"/99")
			}
		},
		rebackWID(id){
			return parseInt(id.substring(3));
		},
		prefixZero(num, n){
			return (Array(n).join(0) + num).slice(-n);
		},
		createId(val){
			return md5(val||(new Date()+"_"+Math.random()))
		},
		async initOSS(){
			ossConfig = await this.request.get("/api/v1/oss/get");
			
			if(ossConfig){
				if(ossConfig.provider == 'MINIO-OSS'){
					ossClient = new Minio.Client(ossConfig);
				} else {
					ossClient = new OSS(ossConfig);
				}
				
				console.log("ossClient inited!",ossClient,ossConfig);
			}
		},
		checkEmail(email){
		  if(!email || !/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-_]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/.test(email)){
				this.$message.error('请输入正确的邮箱格式');
		    return false;
		  }
		  return true;
		},
		checkPassword(password){
		  if(!password || password.length<6){
				this.$message.error('请输入至少6位的密码');
		    return false;
		  }
		  return true;
		},
		checkPasswordConfirm(password,repassword){
		  if(!this.checkPassword(repassword) || password!=repassword){
		    return false;
		  }
		  return true;
		},
    copy(data){
      return JSON.parse(JSON.stringify(data));
    },
    format(fmt='yyyy-MM-dd HH:mm:ss',date) { //author: meizz 
      if(!date){
        date = new Date();
      }
      var o = {
          "M+": date.getMonth() + 1, //月份 
          "d+": date.getDate(), //日 
          "h+": date.getHours(), //小时 
          "H+": this.getMakeZero(date.getHours()), //小时 
          "m+": date.getMinutes(), //分 
          "s+": date.getSeconds(), //秒 
          "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
          "S": date.getMilliseconds() //毫秒 
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o){
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      }
      return fmt;
    },
    getMakeZero(s){
      return s < 10 ? '0' + s : s;
    },
    object2urlparams(data){
      return Object.keys(data).map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
      }).join("&");
    },
    fixCommunityRresourceList(list) {
      console.log(list, Array.isArray(list));
      if (Array.isArray(list)) {
        list.forEach(data => {
          if (data.authorHeadImg){
            this.fixRresource(data.authorHeadImg);
          }
          
          if (data.resources){
            data._resources = [];
            data.resources.forEach(resource => {
              this.fixRresource(resource);
              data._resources.push(resource.url);
            });
          }
          
          if (data.topResources){
            data.topResources.forEach(resource => {
              console.log(resource);
              this.fixRresource(resource);
            });
          }
        });
        return list;
      } else return [];
    },
    fixRresourceList(list){
      if (Array.isArray(list)) {
        list.forEach(resource => {
          this.fixRresource(resource);
        })
      }
      return list;
    },
    fixRresource(resource) {
      resource.url && (resource.url = resource.url.replace("OSSAccessKeyId", "OSS_AccessKeyId"));
      resource.thumbUrl && (resource.thumbUrl = resource.thumbUrl.replace("OSSAccessKeyId", "OSS_AccessKeyId"));
      resource.screenshotUrl && (resource.screenshotUrl = resource.screenshotUrl.replace("OSSAccessKeyId", "OSS_AccessKeyId"));
      return resource;
    },
		toBlob (base64Data) {
			let byteString = base64Data
			if (base64Data.split(',')[0].indexOf('base64') >= 0) {
				byteString = atob(base64Data.split(',')[1]) // base64 解码
			} else {
				byteString = unescape(base64Data.split(',')[1])
			}
			// 获取文件类型
			let mimeString = base64Data.split(';')[0].split(":")[1] // mime类型

			// ArrayBuffer 对象用来表示通用的、固定长度的原始二进制数据缓冲区
			// let arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
			// let uintArr = new Uint8Array(arrayBuffer) // 创建视图

			let uintArr = new Uint8Array(byteString.length) // 创建视图

			for (let i = 0; i < byteString.length; i++) {
				uintArr[i] = byteString.charCodeAt(i)
			}
			// 生成blob
			const blob = new Blob([uintArr], {
				type: mimeString
			})
			// 使用 Blob 创建一个指向类型化数组的URL, URL.createObjectURL是new Blob文件的方法,可以生成一个普通的url,可以直接使用,比如用在img.src上
			return blob
		},
		async doUploadFile(file,configs = {}, cb){
			// provider: ALIYUN-OSS 和 MINIO-OSS
			if(!configs.maxSize){configs.maxSize = 128*1024*1024;} // 128M
			
			const fileName = file.name
			const mineType = file.type
			const fileSize = file.size
			const metadata = {
				"content-type": mineType,
				"content-length": fileSize
			}
			if(!ossConfig.path){ossConfig.path = "/";}
			if(ossConfig.path[ossConfig.path.length-1]!="/"){ossConfig.path += '/';} //尾端补齐 "/"
			if(!configs.uploadPath){configs.uploadPath = '';}
			if(configs.uploadPath && configs.uploadPath[configs.uploadPath.length-1]!="/"){configs.uploadPath.path += '/';} //尾端补齐 "/"
			
			const uploadPath = ossConfig.path + configs.uploadPath + this.createId() +'.'+(fileName.split(".").pop());
			const uploadPathWithHost = 'http://' + ossConfig.endPoint + '/' + ossConfig.bucket + "/" + uploadPath;
			
			if(ossConfig.provider == 'MINIO-OSS'){
				
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend =  (e) => {
					const dataurl = e.target.result
					//base64转blob
					const blob = this.toBlob(dataurl)
					//blob转arrayBuffer
					let reader2 = new FileReader()
					reader2.readAsArrayBuffer(blob)
				
					reader2.onload = function(ex) {
						//定义流
						let bufferStream = new stream.PassThrough();
						//将buffer写入
						bufferStream.end(new Buffer(ex.target.result));
						//上传
						ossClient.putObject(ossConfig.bucket, uploadPath, bufferStream, fileSize, metadata, (err, etag) => {
							
							cb && cb(err, etag, uploadPathWithHost);
						})
					}
				}
			} else {
				try {
					const result = await ossClient.put(
						uploadPath,file
					);
					cb && cb(null, result, result.url);
				} catch (e) {
					cb && cb(e, null, null);
				}
			}
			
		},
		n2c(num) {
			if (!/^\d*(\.\d*)?$/.test(num)) {
				return num;
			}
			var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
			var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
			var a = ("" + num).replace(/(^0*)/g, "").split("."),
				k = 0,
				re = "";
			for (let i = a[0].length - 1; i >= 0; i--) {
				switch (k) {
						case 0:
								re = BB[7] + re;
								break;
						case 4:
								if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
										re = BB[4] + re;
								break;
						case 8:
								re = BB[5] + re;
								BB[7] = BB[5];
								k = 0;
								break;
				}
				if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
				if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
				k++;
			}
			if (a.length > 1) //加上小数部分(如果有小数部分) 
			{
				re += BB[6];
				for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
			}
			var res = re.split("");
			if(res.length>1){
				if(res[0]=="一" && res[1]=="十"){
					res.shift();
					re = res.join("");
				}
			}
			return re;
		},
  }
};

export default Common;