<template>
  <div>

    <div class="search-box mt-10">
			<!-- <el-button style="float:right" type="success" icon="el-icon-circle-plus-outline" @click="doAdd" >新增</el-button> -->
      <el-form :inline="true" :model="formData" class="user-form-inline" size="mini">
        <!-- <el-form-item label="文章名称">
          <el-input 
          v-model="formData.title"
          clearable :readonly="isSearching"
          placeholder="请输入关键词" 
          style="width:120px"
          @keyup.enter.native="doSearch"
          @clear="doReset"
          @blur="doBlur"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="状态">
          <el-select 
            v-model="formData.syncing" 
            placeholder="请选择" 
            style="width:100px">
            <el-option label="全部" value=""></el-option>
            <el-option label="正常" :value="true"></el-option>
            <el-option label="停用" :value="false"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="doSearch" :loading="isSearching">查询</el-button>
        </el-form-item> -->
      </el-form>

    </div>
    <el-divider></el-divider>
    <div>
      <el-table
        :data="list"
        v-loading="isSearching"
        style="width: 100%"
        row-key="id"
        border
        size="mini">
				<el-table-column
					prop="listorder"
					label="排序"
					width="80"
					align="center"
				></el-table-column>
        <el-table-column
          prop="title"
          label="奖项名称"
        >
				<template slot-scope="props">
					<el-link v-if="props.row.isLink" :href="props.row.linkUrl" target="_blank">{{props.row.title}}</el-link>
					<el-link v-else :href="'http://www.abc.com/id/'+props.row.id" target="_blank">{{props.row.title}}</el-link>
				</template>
				
				
        </el-table-column>
				<el-table-column
          prop="createAt"
          label="创建时间"
          width="140"
          align="center"
        >
					<template slot-scope="props">
					{{$root.format('yyyy-MM-dd HH:mm:ss',new Date(props.row.createAt))}}
					</template>
				</el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="100"
          align="center"
        >
           <template slot-scope="props">
             <el-tag type="info" size="mini" effect="dark" v-if="!props.row.status">停用</el-tag>
             <el-tag type="success" size="mini" effect="dark" v-if="props.row.status">正常</el-tag>
           </template>
        </el-table-column>
        <el-table-column 
          label="操作" 
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="doEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="doDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-divider></el-divider>
      <!-- <div class="ta-r">
        <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next"
          @current-change="doChangePage"
          :page-size="pagination.size"
          :current-page="pagination.page"
          :total="total">
        </el-pagination>
      </div> -->

    </div>
		<NewsEdit :data="currentData" :show.sync="showFormEdit" :isEdit="isEdit"/>
  </div>
</template>

<script>
import NewsEdit from '@/components/NewsEdit';
export default {
  name: 'News-List',
  components: {
    NewsEdit
  },
  data(){
    
    return {
      formData:{
        title:""
      },
			isEdit:false,
      pagination:{
        size:10,
        page:1,
      },
      total:0,
      list:[],
      isSearching:false,
      showFormEdit:false,
      currentData:{}
    };
  },
  watch: {
    showFormEdit(newData){
      if(!newData){
        this.doSearch(false);
      }
    }
  },
  mounted () {
		// this.request.zd.create('NEWS',{total:0,list:[]});
    this.doSearch();
  },
  methods: {
		doAdd(){
			this.currentData = {};
			this.isEdit = false;
			this.showFormEdit = true;
		},
    doEdit(index,row){
      console.log(row);
      this.currentData = this.$root.copy(row);
			this.isEdit = true;
      this.showFormEdit = true;
    },
    doDelete(index,row){
      this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.request.delete(`/api/v1/postings/${row.postingId}`).then(res=>{

          if(!res){return ;}
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.doSearch(false);
        })
        
      }).catch(() => {
                
      });
      
    },

    async getList(){
      this.isSearching = true;
			this.list = [];
			this.total = 0;
			var result = await this.request.zd.get('NEWS');
			if(result){
				// this.list = result.list || [];
				this.total = result.total || 0;
			}
			this.isSearching = false;
			
			return this.list;
			
   //    this.request.get(url).then(res=>{
   //      this.isSearching = false;
   //      this.list = [];
   //      this.total = 0;
   //      this.pagination.page = 1;
   //      if(!res){return ;}
   //      this.list = res.results || [];
   //      // this.list.map(d=>{
   //      //   d.resources.unshift({type:"IMAGE",url:d.thumbnailUrl});
   //      // })
   //      this.total = res.total;
   //      // this.total = 646;
   //      this.pagination.page = res.page;

   //    });
    },
    doSearch(resetPage = true){
      // if(!this.formData.collectUserName){
      //   this.$message.warning("搜索内容不能为空");
      //   return ;
      // }
      

      var formData = {...this.pagination,...this.formData};
      if(resetPage){formData.page = 1;}
      var params = this.$root.object2urlparams(formData);


      this.getList(`/api/v1/postings?${params}`);
      
    },
    doChangePage(currentPage){
      var formData = {...this.pagination,...this.formData};
      formData.page = currentPage;
      var params = this.$root.object2urlparams(formData);
      this.getList(`/api/v1/postings?${params}`);
    },
    doBlur(){
      // if(!this.formData.collectUserName){this.doReset();}
    },
    doReset(){
      this.list = [];
      this.total = 0;
      this.pagination.page = 1;
      this.doSearch();
      // this.formData.collectType = 'INS';
    },
  }
}
</script>
<style lang="less" scoped>
.user-form-inline{
  .search-box{
    .el-select .el-input{
      width:160px;
    }
  }
}
</style>