<template>
<div>
  <el-drawer
    :visible.sync="show"
    direction="rtl"
    size="70%"
    :withHeader="false"
    :close-on-press-escape="false"
    :before-close="close">
    <div class="mt-10"></div>
      <el-divider content-position="left">基本内容</el-divider>
			
      <el-form :model="formData" label-width="120px" size="small">
				
				<el-form-item label="所属届" v-if="$root.pk == 'APD'">
				  {{data.name}}
				</el-form-item>
				
        <el-form-item label="年鉴标题(中文)">
          第{{$root.n2c(formData.id)}}届亚太设计年鉴
        </el-form-item>
				<el-form-item label="年鉴内容(中文)">
          <div id="edit-content"></div>
        </el-form-item>
				
				
				<el-form-item label="年鉴标题(英文)">
				  Asia-Pacific Design {{formData.id}}
				</el-form-item>
				<el-form-item label="年鉴内容(英文)">
				  <div id="edit-econtent"></div>
				</el-form-item>
				
				<el-form-item label="轮播图集">
				  <UploadListBox  ref="upload_images" :uid="0" accept="image/*"></UploadListBox>
				</el-form-item>
				

        <el-form-item label="">
          <el-button type="primary" @click="doSubmit" :loading="isLoading">保 存</el-button>
        </el-form-item>

      </el-form>

  </el-drawer>

</div>

</template>

<script>
import E from "wangeditor"
var md5 = require("blueimp-md5")
const KEY = 'APD';
const defaultData = {
	id:0,
	title:"",etitle:"",
	content:"",econtent:"",
	createAt:"",images:[]
};

import UploadListBox from "/src/components/UploadListBox.vue"
export default {
  name: 'HistoryEdit',
  components: {
    UploadListBox
  },
  props: {
    data: Object,
    show: Boolean,
		isEdit: Boolean
  },
  data(){
    return {
      formData:this.$root.copy(defaultData),
			isLoading:false,
			editor:null,
			eeditor:null,
			ossConfig:{}
    }
  },
	mounted(){
		// this.initOSS();
		
	},
  watch: {
		show(newData){
			if(!this.editor){
				this.initEditor();
			}
			if(newData){
				this.$nextTick(()=>{
					this.initData()
				})
				
			}
		}
  },
	
  methods:{
		initEditor(){
			this.$nextTick(()=>{
				this.editor = new E("#edit-content")
				this.editor.config.excludeMenus = [
					'emoticon',
					'video',
					'todo',
					'table',
					'quote',
					'code',
				]
				this.editor.config.height = 150
				this.editor.config.lineHeights = ['1.2','1.3','1.4','1.5','1.75','2', '2.5', '3']
				this.editor.config.customUploadImg =  (resultFiles, insertImgFn)=> {
						this.doUploadFile(resultFiles[0],'history/content/',(err,etag,uploadPathWithHost)=>{
							if(err){this.$message.error("上传失败,请重新上传");return;}
							insertImgFn(uploadPathWithHost)
						});
				}
				this.editor.create();
				this.editor.txt.html("<p></p>");
				
				
				this.eeditor = new E("#edit-econtent")
				this.eeditor.config.excludeMenus = [
					'emoticon',
					'video',
					'todo',
					'table',
					'quote',
					'code',
				]
				this.eeditor.config.height = 150
				this.eeditor.config.lineHeights = ['1.2','1.3','1.4','1.5','1.75','2', '2.5', '3']
				this.eeditor.config.customUploadImg =  (resultFiles, insertImgFn)=> {
						this.doUploadFile(resultFiles[0],'history/content/',(err,etag,uploadPathWithHost)=>{
							if(err){this.$message.error("上传失败,请重新上传");return;}
							insertImgFn(uploadPathWithHost)
						});
				}
				this.eeditor.create()
				this.eeditor.txt.html("<p></p>");
			})
		},
		initData(){
			var data = this.$root.copy(defaultData);
			this.editor.txt.html("<p></p>");
			this.eeditor.txt.html("<p></p>");
			if(this.data && this.data.id){
				this.isEdit = true;
				this.getContent(this.data.id)
			}
			
			// this.$set(this,'formData',{...data});
			// console.log('this.formData',this.formData);
			
		},
		doCoverUpload(){
			this.doUploadFile(this.$refs.cover.files[0],'history/cover/',(err,etag,uploadPathWithHost)=>{
				if(err){this.$message.error("上传失败,请重新上传");return;}
				this.formData.cover = uploadPathWithHost;
			});
		},
		doUploadFile(file,uploadPath,cb){
			this.$root.doUploadFile(file,{
				uploadPath
			},cb);
			
		},
		async getContent(id){
			var data = await this.request.zd.get(KEY+"_"+id);
			if(data && data.content){
				this.editor.txt.html(data.content);
			}
			if(data && data.econtent){
				this.eeditor.txt.html(data.econtent);
			}
			if(data && data.images){
				this.$refs.upload_images.updateList(data.images);
			}
			
			if(data){
				this.$set(this,'formData',{...this.formData,...data})
			} else {
				this.formData.id = this.data.id;
			}
			
		},
    close(){
			this.formData=this.$root.copy(defaultData)
      this.$emit('update:show',false);
    },
		fixParams(){
			var params = this.$root.copy(this.formData)
			params.content && (delete params.content);
			params.econtent && (delete params.econtent);
			// params.etitle && (delete params.etitle);
			return params;
		},
    async doSubmit(){

			// 检测内容
			// if(!this.formData.title){
			// 	this.$message.error("标题不能为空!");
			// 	return ;
			// }
			
			this.isLoading = true;
			this.formData.content = this.editor.txt.html();
			this.formData.econtent = this.eeditor.txt.html();
			this.formData.images = this.$refs.upload_images.getList();
			var hasData = await this.request.zd.get(KEY+'_'+this.formData.id);
			
			var params;
			if(!hasData){
				this.formData.createAt = Date.now();
				await this.request.zd.create(KEY+'_'+this.formData.id,this.$root.copy(this.formData));
			} else {
				await this.request.zd.update(KEY+'_'+this.formData.id,this.$root.copy(this.formData));
			}
			
      this.isLoading = false;
			this.$message.success("保存成功");
			this.close();
			
    }
  },
}
</script>

<style scoped lang="less">
.cover-uploader{
	img {
		width:160px;
		max-height:400px;
		margin-bottom:10px;
		display: block;
	}
}
#edit-content{
	width:90%;
}
</style>
