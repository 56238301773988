<template>
  <div id="app">
    <Loading :loading="loading"></Loading>
		<images-viewer ref="viewer"></images-viewer>
    <router-view></router-view>
  </div>
  
</template>

<script>
import Loading from './components/Loading.vue'

export default {
  name: 'App',
  components: {
    Loading
  },
  data(){

    return {
      loading:true,
			pk: process.env.VUE_APP_PK
    }
  },
  mounted () {
    setTimeout(() => {
      this.loading = false;
    }, 100);
  }
}
</script>

<style>

#app {
  height:100%;
}

</style>
