<template>
<div>
  <el-drawer
    :visible.sync="show"
    direction="rtl"
    size="70%"
    :withHeader="false"
    :close-on-press-escape="false"
    :before-close="close">
    <div class="mt-10"></div>
      <el-divider content-position="left">基本内容</el-divider>
			
      <el-form :model="formData" label-width="120px" size="small">
				
				<el-form-item label="编委头像">
					<div class="cover-uploader">
						<img v-if="formData.cover" :src="formData.cover" />
						<input type="file" ref="cover" @change="doCoverUpload"/>
					</div>
					<!-- <el-upload
						class="cover-uploader"
						action=""
						ref="cover"
						:http-request="doUpload"
						:show-file-list="false">
						<img v-if="formData.coverUrl" :src="formData.coverUrl" class="cover">
						<i v-else class="el-icon-plus cover-uploader-icon"></i>
					</el-upload> -->
				</el-form-item>
				<!-- <el-form-item label="所属赛事">
					<el-select v-model="formData.mainAwrad" placeholder="请选择">
						<el-option label="2021" value="2021"></el-option>
						<el-option label="2022" value="2022"></el-option>
					</el-select>
				</el-form-item> -->
				
				<el-form-item label="所属届" v-if="$root.pk == 'APD'">
				  <el-select v-model="formData.awardStage" placeholder="请选择所属届" :disabled="data&&data.id">
				    <el-option :label="item.name" :value="item.id" v-for="(item) in $root.awardStageList" :key="item.id"></el-option>
				  </el-select>
				</el-form-item>
				
				<el-form-item label="所属分类" v-if="$root.pk == 'APD'">
				  <el-select v-model="formData.type" placeholder="请选择所属分类">
				    <el-option label="学术顾问" :value="1"></el-option>
				    <el-option label="特邀编委" :value="2"></el-option>
				  </el-select>
				</el-form-item>
				
				
        <el-form-item label="编委名称(中文)">
          <el-input
            v-model="formData.name" 
						placeholder="请输入编委名称(中文)"
            autocomplete="off" 
            style="width:80%"
          >
					</el-input>
        </el-form-item>
				<el-form-item label="所在地区(中文)">
				  <el-input
				    v-model="formData.city" 
						placeholder="请输入编委所在地区(中文)"
				    autocomplete="off" 
				    style="width:80%"
				  >
					</el-input>
				</el-form-item>
				<el-form-item label="编委简介(中文)">
				  <el-input
						type="textarea" :rows="3"
				    v-model="formData.description" 
						placeholder="请输入编委简介(中文)"
				    autocomplete="off" 
				    style="width:80%"
				  >
					</el-input>
				</el-form-item>
				
				<!-- 英文 -->
				<el-form-item label="编委名称(英文)">
				  <el-input
				    v-model="formData.ename" 
						placeholder="请输入编委名称(英文)"
				    autocomplete="off" 
				    style="width:80%"
				  >
					</el-input>
				</el-form-item>
				<el-form-item label="所在地区(英文)">
				  <el-input
				    v-model="formData.ecity" 
						placeholder="请输入编委所在地区(英文)"
				    autocomplete="off" 
				    style="width:80%"
				  >
					</el-input>
				</el-form-item>
				<el-form-item label="编委简介(英文)">
				  <el-input
						type="textarea" :rows="3"
				    v-model="formData.edescription" 
						placeholder="请输入编委简介(英文)"
				    autocomplete="off" 
				    style="width:80%"
				  >
					</el-input>
				</el-form-item>
				<!-- ~~~~~~~~~ -->
				
				
				
				
				<el-form-item label="排序序号">
				  <el-input-number
				  	v-model="formData.listorder" 
				  	placeholder="请输入排序数字"
				  	autocomplete="off" 
				  	style="width:200px;"
				  ></el-input-number>
					<el-alert
						title="注: 数字越高越靠前"
						type="info"
						left
						show-icon
						:closable="false"
						style="margin-top:10px;width:200px">
					</el-alert>
				</el-form-item>
				
				<el-form-item label="状态">
					<el-select v-model="formData.status" placeholder="请选择">
						<el-option label="启用" :value="1"></el-option>
						<el-option label="停用" :value="0"></el-option>
					</el-select>
				</el-form-item>

        <el-form-item label="">
          <el-button type="primary" @click="doSubmit" :loading="isLoading">保 存</el-button>
        </el-form-item>

      </el-form>

  </el-drawer>

</div>

</template>

<script>
import E from "wangeditor"
var md5 = require("blueimp-md5")

const defaultData = {
	id:"",
	cover:"",
	awardStage:18,
	type:1,
	name:"",ename:"",
	city:"",ecity:"",
	content:"",econtent:"",
	
	listorder:0,
	createAt:"",
	status:1
};
export default {
  name: 'JudgesEdit',
  components: {
    
  },
  props: {
    data: Object,
    show: Boolean,
		isEdit: Boolean
  },
  data(){
    return {
      formData:{...defaultData},
			isLoading:false,
			editor:null,
			ossConfig:{}
    }
  },
	mounted(){
		// this.initOSS();
		
	},
  watch: {
		show(newData){
			if(!this.editor){
				// this.initEditor();
			}
			if(newData){
				this.$nextTick(()=>{
					this.initData()
				})
				
			}
		}
  },
	
  methods:{
		initEditor(){
			this.$nextTick(()=>{
				this.editor = new E("#edit-content")
				this.editor.config.excludeMenus = [
					'emoticon',
					'video',
					'todo',
					'table',
					'quote',
					'code',
				]
				this.editor.config.height = 150
				this.editor.config.lineHeights = ['1.2','1.3','1.4','1.5','1.75','2', '2.5', '3']
				this.editor.config.customUploadImg =  (resultFiles, insertImgFn)=> {
				    // resultFiles 是 input 中选中的文件列表
				    // insertImgFn 是获取图片 url 后，插入到编辑器的方法
						this.doUploadFile(resultFiles[0],'news/content/',(err,etag,uploadPathWithHost)=>{
							if(err){this.$message.error("上传失败,请重新上传");return;}
							insertImgFn(uploadPathWithHost)
						});
				    // 上传图片，返回结果，将图片插入到编辑器中
				    // insertImgFn(imgUrl)
				}

				this.editor.create()
			})
		},
		initData(){
			var data = this.$root.copy(defaultData);
			if(this.data && this.data.id){
				this.isEdit = true;
				data = {
					...data,
					...this.$root.copy(this.data)
				};
				// this.getContent(data.id)
			} else {
				console.log('editor',this.editor);
				// this.editor.txt.html("<p></p>");
			}
			
			console.log('data',data);
			
			this.$set(this,'formData',data);
			console.log('this.formData',this.formData);
			
		},
		doCoverUpload(){
			this.doUploadFile(this.$refs.cover.files[0],'judges/cover/',(err,etag,uploadPathWithHost)=>{
				if(err){this.$message.error("上传失败,请重新上传");return;}
				this.formData.cover = uploadPathWithHost;
			});
		},
		doUploadFile(file,uploadPath,cb){
			this.$root.doUploadFile(file,{
				uploadPath
			},cb);
			
		},
		async getContent(id){
			var data = await this.request.zd.get("NEWS_"+id);
			if(data && data.content){
				this.formData.content = data.content;
				this.editor.txt.html(this.formData.content);
			}
		},
    close(){
      this.$emit('update:show',false);
    },
		fixParams(){
			var params = this.$root.copy(this.formData)
			// params.content && (delete params.content);
			// params.econtent && (delete params.econtent);
			// params.etitle && (delete params.etitle);
			return params;
		},
    async doSubmit(){

			// 检测内容
			if(!this.formData.name){
				this.$message.error("名称不能为空!");
				return ;
			}
			
			this.isLoading = true;
			// this.formData.content = this.editor.txt.html();
			var result = await this.request.zd.get('JUDGES'); //Judges JUDGES
			var list = result? (result.list||[]) : [];
			
			var prefix = '';
			if(!this.formData.id){
				this.formData.createAt = Date.now();
				this.formData.id = md5(this.formData.createAt);
				prefix = 'JUDGES_'+this.formData.awardStage+'_'+this.formData.id;
				await this.request.zd.create(prefix,this.formData);
			} else {
				prefix = 'JUDGES_'+this.formData.awardStage+'_'+this.formData.id;
				await this.request.zd.update(prefix,this.formData);
			}
			
      this.isLoading = false;
			this.$message.success("保存成功");
			this.close();
			
    }
  },
}
</script>

<style scoped lang="less">
.cover-uploader{
	img {
		width:160px;
		max-height:400px;
		margin-bottom:10px;
		display: block;
	}
}
#edit-content{
	width:90%;
}
</style>
