<template>
  <div>

    <div class="search-box mt-10" >
      <el-form :inline="true" :model="formData" class="user-form-inline" size="mini">
				<div class="flex between">
					<div>
						<div>
						<el-form-item label="筛选IP">
							<el-input 
							v-model="formData.clientIps"
							clearable :readonly="isSearching"
							placeholder="请输入查询IP" 
							style="width:140px"
							@keyup.enter.native="doSearch"
							@clear="doReset"
							@blur="doBlur"></el-input>
						</el-form-item>
						<el-form-item label="作品编号">
							<el-input 
							v-model="formData.wid"
							clearable :readonly="isSearching"
							placeholder="作品编号" 
							style="width:120px"
							@keyup.enter.native="doSearch"
							@clear="doReset"
							@blur="doBlur"></el-input>
						</el-form-item>
						
						<el-form-item label="日期">
							<!-- v-model="value2" -->
							<el-date-picker
						      v-model="formData.createDate"
									clearable @clear="doReset" @change="doSearch"
						      type="daterange"
						      align="right"
						      unlink-panels
						      range-separator="至"
						      start-placeholder="开始日期"
						      end-placeholder="结束日期"
									value-format="yyyy-MM-dd"
						      :picker-options="pickerOptions">
						    </el-date-picker>
						</el-form-item>
								
						</div>
					</div>
					<div>
						<el-form-item style="width:120px;">
							<el-button type="primary" icon="el-icon-search" @click="doSearch" :loading="isSearching">查询</el-button>
							<div class="mt-10"></div>
							<el-button type="success" icon="el-icon-download" @click="doExport" :loading="isExporting">导出数据包</el-button>
							
						</el-form-item>
						
						
					</div>
				</div>
      </el-form>

    </div>
    <el-divider></el-divider>
    <div>
      <el-table
        :data="list"
        v-loading="isSearching"
        style="width: 100%"
        row-key="id"
        border
        size="mini">
				<el-table-column
					prop="headImgUrl"
					label="头像"
					width="100"
					align="center"
				>
					<template slot-scope="props">
						<el-avatar :src="props.row.wxMpUserInfo.headImgUrl"></el-avatar>
					</template>
				
				</el-table-column>
				<el-table-column
					prop="nickname"
					label="微信名"
					width="120"
					align="center"
				>
					<template slot-scope="props">
						{{props.row.wxMpUserInfo.nickname}}
					</template>
				
				</el-table-column>
				<el-table-column
				  prop="clientIp"
				  label="IP"
				  width="140"
				  align="center"
				>
					<template slot-scope="props">
						<el-link v-if="props.row.clientIp" type="success" @click="toIPCheckPage(props.row.clientIp)">{{props.row.clientIp}}</el-link>
											
					</template>
				</el-table-column>
				
				<el-table-column
				  prop="createTime"
				  label="投票时间"
				  width="140"
				  align="center"
				>
					<template slot-scope="props">
						{{props.row.createTime}}
					<!-- {{$root.format('yyyy-MM-dd HH:mm',new Date(props.row.createTime))}} -->
					</template>
				</el-table-column>
				
				
				<el-table-column
					prop="id"
					label="被投稿者编号"
					width="100"
					align="center"
				>
					<template slot-scope="props" >
						<el-link v-if="props.row.awardWorkInfo.userId" type="warning" @click="showUserById(props.row.awardWorkInfo.userId)">{{$root.createUID(props.row.awardWorkInfo.userId)}}</el-link>
					
					</template>
				</el-table-column>
				
				<el-table-column
				  prop=""
				  label="被投稿作品ID"
					width="100"
					align="center"
				>
					<template slot-scope="props" v-if="props.row.awardWorkInfo && props.row.awardWorkInfo.id">
						{{$root.createWID(props.row.awardWorkInfo)}}
					</template>
				</el-table-column>
				
        <el-table-column
          prop=""
          label="被投稿作品名称"
        >
					<template slot-scope="props" v-if="props.row.awardWorkInfo.content">
						<template v-if="props.row.awardWorkInfo.id">
							<p>当前网络总票数：{{props.row.awardWorkInfo.voteCount}}</p>
							<el-link type="success" v-if="props.row.awardWorkInfo.content.title" @click="doView(props.$index, props.row)">
							{{props.row.awardWorkInfo.content.title}}
							</el-link>
						</template>
						<template v-else>作品已被删除 ID:{{props.row.awardWorkId}}</template>
						
					</template>
        </el-table-column>
				
				
      </el-table>

      <el-divider></el-divider>
      <div class="ta-r">
        <el-pagination
          background
          hide-on-single-page
          layout="total,  prev, pager, next, jumper"
          @current-change="doChangePage"
          :page-size="pagination.pageSize"
          :current-page="pagination.page"
          :total="total">
        </el-pagination>
      </div>

    </div>
		<WorkEdit :data="currentData" :show.sync="showFormEdit" :isEdit="isEdit"/>
		<UserEdit :data="currentUserData" :show.sync="showUserFormEdit" :isEdit="true"/>
		<ExcelViewer type="work" :show.sync="showExcelViewer"/>
  </div>
</template>

<script>
import UserEdit from '@/components/UserEdit';
import WorkEdit from '@/components/WorkEdit';
import ExcelViewer from '@/components/ExcelViewer';
var timer;
export default {
  name: 'Award-List',
  components: {
    WorkEdit,
		UserEdit,
		ExcelViewer
  },
  data(){
    
    return {
			pickerOptions: {
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						// start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '昨天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
						end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '本月',
					onClick:(picker) =>{
						const end = new Date();
						const start = new Date();
						start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
						
						let lastDay = new Date(end.getFullYear(), end.getMonth()+1, 0).getDate();
						end.setTime(new Date(end.getFullYear(), end.getMonth(), lastDay));
						picker.$emit('pick', [start, end]);
					}
				},{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			
      formData:{
        clientIps:"",
				wid:"",
				createDate:["",""],
      },
			isEdit:false,
      pagination:{
        pageSize:10,
        page:1,
      },
      total:0,
      list:[],
			exportList:[],
      isSearching:false,
      isExporting:false,
      showFormEdit:false,
      currentData:{},
			showUserFormEdit:false,
			showExcelViewer:false,
			currentUserData:{},
    };
  },
  watch: {
    showFormEdit(newData){
      if(!newData){
        this.doSearch(false);
      }
    }
  },
  mounted () {
		// this.request.zd.create('NEWS',{total:0,list:[]});
    this.doSearch();
  },
  methods: {
		async doExport(){
			this.$confirm('此操作将会在服务端进行打包压缩等操作,请留意在非高峰期间操作.', '提示', {
				confirmButtonText: '确定,导出',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				
				this.isExporting = true;
				await this.request.get(`/api/v1/exportTask/exportAwardWorkWxMpVote`);
				this.isExporting = false;
				this.$root.$emit('openExportList');
			}).catch(() => {
				        
			});
		},
		async doAwardWorkStatusChange(item){
			var targetStatus = this.$root.getAwardWorkStatus(item.awardWorkStatus);
			
			this.$confirm(`
				正在更改作品状态为: <b>${targetStatus.aname}</b>
				<br/>
				用户端将显示作品状态为: <b>${targetStatus.name}</b>
			`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString:true
			}).then(async () => {
				await this.request.post('/api/v1/awardWork/manage/update',{
					id:item.id,
					awardWorkStatus:item.awardWorkStatus
				});
				item._awardWorkStatus = item.awardWorkStatus;
				
				this.$message({
					type: 'success',
					message: '更改成功!'
				});
			}).catch(() => {
				item.awardWorkStatus = item._awardWorkStatus;
			});
			
			
		},
		doEdit(index,row){
			this.currentData = this.$root.copy(row);
			this.isEdit = true;
			this.showFormEdit = true;
		},
		toIPCheckPage(ip){
			if(ip) window.open(`https://www.ip138.com/iplookup.asp?ip=${ip}&action=2`);
		},
		async showUserById(id){
			var userData = await this.request.get('/api/v1/frontDeskUser/get?id='+id);
			if(userData && userData.id){
				try{
					userData.config = JSON.parse(userData.config)
				}catch(e){
					userData.config = {};
				}
				this.showUserFormEdit = true;
				this.$set(this,'currentUserData',userData);
			}
			
			
		},
    doView(index,row){
			var sid = this.$root.md5(row.awardWorkId);
			if(this.$root.pk=='APD'){
				if(process.env.NODE_ENV === 'production'){
					window.open("https://apd-design.cn/#/Work/Content/"+sid+"/"+row.awardWorkId+"/0");
				} else if(process.env.NODE_ENV === 'development'){
					window.open("http://localhost:8081/#/Work/Content/"+sid+"/"+row.awardWorkId+"/0");
				}
			} else if(this.$root.pk=='EPA'){
				if(process.env.NODE_ENV === 'production'){
					window.open("http://design.zhgxfz.com/#/Works/Content/"+sid+"/"+row.awardWorkId+"/0");
				} else if(process.env.NODE_ENV === 'development'){
					window.open("http://epa-web.sizne.net/#/Works/Content/"+sid+"/"+row.awardWorkId+"/0");
				}
			}
			
    },
    doDelete(index,row){
      this.$confirm('此操作将永久删除该作品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
				
        this.request.delete(`/api/v1/awardWork/manage/delete?id=${row.id}`).then(res=>{

          if(!res){return ;}
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.doSearch(false);
        })
        
      }).catch(() => {
                
      });
      
    },

    async getList(url){
      this.isSearching = true;
			
      this.request.get(url).then(res=>{
        this.isSearching = false;
        this.list = [];
        this.total = 0;
        this.pagination.page = 1;
        if(!res){return ;}
        var list = res.rows || [];
				list.forEach(item=>{
					try{
						item.awardWorkInfo.content = JSON.parse(item.awardWorkInfo.content);
					}catch(e){
						if(!item.awardWorkInfo){item.awardWorkInfo = {content:{title:"作品已删除"},userId:0,id:0};}
						else {
							item.awardWorkInfo.content = {};
						}
					}
					this.$root.initAwardType(item.awardWorkInfo);
					// let status = this.statusList.find(temp => temp.status == item.awardWorkStatus);
					// item.awardWorkStatusName = status ? status.name :"已入选";
				})
				this.list = list
				
        // this.list.map(d=>{
        //   d.resources.unshift({type:"IMAGE",url:d.thumbnailUrl});
        // })
        this.total = res.total;
        // this.total = 646;
        this.pagination.page = res.page;

      });
    },
		createSearchData(){
			var params = {};
			
			if(this.formData.clientIps && this.formData.clientIps){
				params.clientIps = this.formData.clientIps;
			}
			if(this.formData.createDate && this.formData.createDate[0]){
				params.createDateAfter = this.formData.createDate[0];
			}
			if(this.formData.createDate && this.formData.createDate[1]){
				params.createDateBefore = this.formData.createDate[1];
			}
			if(this.formData.wid){
				if(this.formData.wid.length != 9){
					this.$message.warning("作品编号格式错误");
					return false;
				}
				
				var widOK = true;
				var wids = this.formData.wid.split("");
				var awardTypeCode = wids.shift();
				var target = this.$root.awardTypeList.find(item=>item.code == awardTypeCode);
				if(target){
					params.awardType = target.id;
				} else {
					this.$message.warning("匹配不到对应作品类别");
					return false;
				}
				
				var awardStage = parseInt(wids.shift() + wids.shift());
				params.awardStage = awardStage;
				
				var id = parseInt( wids.join("") );
				params.awardWorkIds = [id];
				
			}
			return params;
		},
    doSearch(resetPage = true){
      // if(!this.formData.collectUserName){
      //   this.$message.warning("搜索内容不能为空");
      //   return ;
      // }
      
			var searchParams = this.createSearchData();
			if(!searchParams){return ;}
			var formData = {...this.pagination,...searchParams};
      if(resetPage){formData.page = 1;}
      var params = this.$root.object2urlparams(formData);


      this.getList(`/api/v1/awardWorkWxMpVote/paging?${params}`);
      
    },
    doChangePage(currentPage){
      var searchParams = this.createSearchData();
			if(!searchParams){return ;}
      var formData = {...this.pagination,...searchParams};
      formData.page = currentPage;
      var params = this.$root.object2urlparams(formData);
      this.getList(`/api/v1/awardWorkWxMpVote/paging?${params}`);
    },
    doBlur(){
      // if(!this.formData.collectUserName){this.doReset();}
    },
    doReset(){
      this.list = [];
      this.total = 0;
      this.pagination.page = 1;
      this.doSearch();
      // this.formData.collectType = 'INS';
    },
  }
}
</script>
<style lang="less" scoped>
.user-form-inline{
  .search-box{
    .el-select .el-input{
      width:160px;
    }
  }
}
</style>