<template>
  <div class="main-box">
    <div class="title">{{$route.meta.title}}</div>
  <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Empty',
  components: {
  },
  data(){
    return {};
  },
  mounted () {
    
  }
}
</script>

<style>

</style>
