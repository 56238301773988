<template>
  <el-container>
    <el-header>
      <el-row :gutter="20" justify="space-between" type="flex">
        <el-col :span="6">
          <div class="logo flex middle">
            <img src="~@/assets/common/logo.png" />
            <span>设计比赛·管理系统 <sub>for {{pk}}</sub></span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="menu">
            <div class="user-box">
							<el-button size="mini" style="margin-right:20px;" type="success" icon="el-icon-document-checked" @click="openExportList">查看导出任务</el-button>
							<el-button v-if="this.pk == 'APD'" type="primary" size="mini" style="margin-right:50px;" @click="showFileUpload = true">上传<i class="el-icon-upload el-icon--right"></i></el-button>
							
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  <div class="avatar"><i class="el-icon-user-solid"></i></div> <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-switch-button" command="doLogout">退出</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

            </div>
          </div>
        </el-col>
      </el-row>
      
    </el-header>
    <el-container>
      <el-aside width="256px">
        <HomeMenu></HomeMenu>
      </el-aside>
      <el-container>
        <el-main>
          <router-view></router-view>
					<FileUpload :show.sync="showFileUpload"/>
        </el-main>
        <el-footer>Copyright 2022©佛山市微服信息科技有限公司-版权所有</el-footer>
      </el-container>
    </el-container>
		<el-dialog title="导出任务列表" :visible.sync="isExportListVisible">
		  <el-table :data="exportList" v-loading="isExportListLoading" row-key="id" size="mini">
		    <el-table-column prop="createTime" label="创建日期" width="150"></el-table-column>
		    <el-table-column prop="taskTypeRemark" label="类型" width="100"></el-table-column>
		    <el-table-column prop="taskStatusRemark" label="状态">
					<template slot-scope="scope">
						{{scope.row.taskStatusRemark}}{{scope.row.remark?'|'+scope.row.remark:''}}
					</template>
				</el-table-column>
		    <el-table-column prop="id" label="操作" width="180">
					<template slot-scope="scope">
						<el-button v-if="scope.row.taskStatus== 'FINISH'"
							size="mini"
							type="primary"
							@click="doDonwload(scope.$index, scope.row)">下载</el-button>
							<el-button 
							size="mini"
							type="danger"
							:loading="isExportRemoving"
							@click="doExportRemove(scope.$index, scope.row)">删除</el-button>
					</template>
				</el-table-column>
		  </el-table>
		</el-dialog>
		
  </el-container>
</template>

<script>
import HomeMenu from './HomeMenu';
import FileUpload from '@/components/FileUpload';
var timer;
export default {
  name: 'Home',
  components: {
    HomeMenu,
		FileUpload
  },
  data(){
    
    return {
			pk: process.env.VUE_APP_PK,
			showFileUpload:false,
			isExportRemoving:false,
			isExportListVisible:false,
      isExportListLoading:false,
			exportList:[],
			hasUnFINISH:false
    }
  },
  async mounted () {
		// const awardTypeList = [
		// 	{id:1,code:"A",name:"品牌设计",config:{video:false}},
		// 	{id:2,code:"B",name:"环境图形",config:{video:false}},
		// 	{id:3,code:"C",name:"文字设计",config:{video:true}},
		// 	{id:4,code:"D",name:"出版物及印刷品设计",config:{video:false}},
		// 	{id:5,code:"E",name:"海报设计",config:{video:true}},
		// 	{id:6,code:"F",name:"包装设计",config:{video:false}},
		// 	{id:6,code:"G",name:"商业插画",config:{video:false}},
		// 	{id:8,code:"H",name:"数字设计",config:{video:true}},
		// 	{id:9,code:"I",name:"标志设计",config:{video:false}},
		// ];
		// this.request.zd.create('awardTypeList',awardTypeList);
		
		// const creatorList = [
		// 	{code:"DA",name:"设计公司"},
		// 	{code:"CD",name:"创意指导"},
		// 	{code:"AD",name:"艺术指导"},
		// 	{code:"DD",name:"设计指导"},
		// 	{code:"DS",name:"设计师"},
		// 	{code:"AT",name:"建筑师"},
		// 	{code:"ID",name:"室内设计师"},
		// 	{code:"MD",name:"动态设计师"},
		// 	{code:"WD",name:"网页设计师"},
		// 	{code:"TG",name:"字体设计师"},
		// 	{code:"CW",name:"文案"},
		// 	{code:"ST",name:"策略师"},
		// 	{code:"SL",name:"造型师"},
		// 	{code:"AR",name:"艺术家"},
		// 	{code:"IR",name:"插画师"},
		// 	{code:"PM",name:"项目经理"},
		// 	{code:"PR",name:"制作"},
		// 	{code:"DE",name:"开发"},
		// 	{code:"CO",name:"统筹"},
		// 	{code:"PG",name:"摄影"},
		// 	{code:"CL",name:"客户"},
		// ];
		// this.request.zd.create('creatorList',creatorList);
		
		this.$root.$on('openExportList',  this.openExportList);
  },
  methods: {
    handleCommand(command){
      if(command=='doLogout'){
        window.localStorage.removeItem("token");
        this.$nextTick(()=>{
          this.$router.push('/Login');
        });
      }
    },
		async refreshExportList(){
			if(!this.isExportListVisible || !this.hasUnFINISH){
				if(timer){clearTimeout(timer);}
				return ;
			}
			if(timer){clearTimeout(timer);}
			timer = setTimeout(async ()=>{
				await this.updateExportList();
			},6000);
		},
		async updateExportList(){
			this.isExportListLoading = true;
			let result = await this.request.get("/api/v1/exportTask/paging?page=1&pageSize=5");
			this.exportList = result.rows || [];
			
			this.isExportListLoading = false;
			this.hasUnFINISH = this.exportList.some(item=>item.taskStatus!="FINISH");
			if(this.hasUnFINISH){
				this.refreshExportList();
			}
			
		},
		async openExportList(){
			this.isExportListVisible = true;
			await this.updateExportList();
		},
		doDonwload(index,row){
			window.open(row.resourceUrl);
		},
		async doExportRemove(index,row){
			this.isExportRemoving = true;
			await this.request.delete(`/api/v1/exportTask/delete?id=${row.id}`);
			this.$message.success("删除任务成功");
			this.isExportRemoving = false;
			this.updateExportList();
		},
  }
}
</script>

<style lang="less">
.el-header{
  background-color: #fff;
  box-shadow: 0 1px 2px rgb(150 150 150 / 30%);
  padding: 0 40px;
  position: relative;
  text-align: left;
  height: 60px;
  margin-bottom:40px;
	.avatar{
		font-size: 22px;
		border-radius: 28px;
		display: inline-block;
		width:36px;height:36px;overflow: hidden;
		border:1px solid #606266;
		color:#606266;
		text-align: center;
		vertical-align: middle;
		i{vertical-align: middle;}
	}
  div.logo{
    padding-top:8px;
    cursor: pointer;
		position: relative;
    span{margin-left:10px;color: #5e5e5e;font-size: 22px;}
		sub{
			font-size: 12px; 
			position: absolute; margin-left:79px;
			
			zoom:.7;
			left:0;bottom:-8px;
			// color:#aaa;
			// font-weight: bold;
		}
    img{
      height:42px;vertical-align: bottom;
      &.mini{
        margin-left:10px; 
        padding-top:3px;
        padding-bottom:3px;
        height:30px; 
        border-bottom:2px solid #e6e6e6
      }
    } 
  }
  div.menu{
    text-align: right;
    padding-top:10px;
    .user-box{
      cursor: pointer;
      img{
        height:36px;
        vertical-align: text-bottom;
      }

    }
  }
  
}

.el-main{padding-top:10px}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: transparent;
  color: #576b95;
}
.el-footer {
  line-height: 60px;
  text-align: right;
  color:#aaa;
}
  
</style>
