<template>
  <div class="menu">
    <div v-for="(menu,index) in menus" :key="index">
      <template v-if="menu.children && menu.children.length>0">
        <div class="title hasSub">
          <i v-if="menu.icon" :class="menu.icon"></i>
          <span>{{menu.title}}</span>
        </div>
        <div class="submenu">
          <div v-for="(item,index2) in menu.children" :key="index+'_'+index2" @click="doRoute(item,index,index2)">
            <div class="title2" :class="{active:active==index+'_'+index2}">
              <i v-if="item.icon" :class="item.icon"></i>
              <span>{{item.title}}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="title" @click="doRoute(menu,index)" :class="{active:active==index}">
          <i v-if="menu.icon" :class="menu.icon"></i>
          <span>{{menu.title}}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>


export default {
  name: 'HomeMenu',
  props: {
    
  },
  data(){
    const menus = [];
    this.$router.options.routes.forEach(route=>{
      if(route.path=='/Home'){
        route.children.forEach(menu=>{
          if(menu.meta && menu.meta.isMenu){
            var _menu = {};
            menu.meta && menu.meta.title && (_menu.title = menu.meta.title);
            menu.meta && menu.meta.icon && (_menu.icon = menu.meta.icon);
            menu.path && (_menu.path = route.path+'/'+menu.path);
            _menu.children = [];
            if(menu.children){
              menu.children.forEach(item =>{
                if(item.meta && item.meta.isMenu){
                  var _item = {};
                  item.meta && item.meta.title && (_item.title = item.meta.title);
                  item.path && (_item.path = _menu.path+'/'+item.path);
                  _menu.children.push(_item);
                }
              });
            }
            menus.push(_menu);  
          }
          
        });
      
      }
    });
    
    let active = "";
    for(let i=0,l=menus.length; i<l; i++){
      let menu = menus[i];
      if(menu.path == this.$route.path){
        active = i.toString();
        break ;
      }
      if(menu.children && menu.children.length){
        for(let j=0,ll=menu.children.length; j<ll; j++){
          let item = menu.children[j];
          if(item.path == this.$route.path){
            active = i.toString()+"_"+j.toString();
            break ;
          }
        }
      }
    }


    return {
      menus,
      active
    }
  },
  mounted () {
    
  },
  methods: {
    doRoute(item,index,index2){
      if(item.path == this.$route.path){return ;}
      let _active = index.toString();
      if(typeof index2 != 'undefined') _active += "_"+index2.toString();
      
      this.active = _active;
      this.$router.push(item.path);

    }
  }
}
</script>

<style lang="less" scoped>
@mcolor:#ee8222;
.menu{
  color: #333;
  border-right: solid 1px #e6e6e6;
  min-height: 100vh;
  &>div{
    position: relative;
    margin-bottom:10px;
    &::after{
      content: "";
      position: absolute;
      height: 1px;
      background-color: #e7e7eb;
      left: 76px;
      width: 43%;
      bottom: 0;
    }
  
    .title{
      font-size: 18px;
      padding-left: 35px;
      height: 56px;
      line-height: 56px;
      white-space: nowrap;
      cursor: pointer;
      &:hover{
        background-color: #eff1f3;
      }
      &.active{
        color:@mcolor;
        & i[class^=el-icon-]{
          color:@mcolor;
        }
      }
      
      &.hasSub{
        margin-bottom:0px;
        cursor: default;
        background-color: transparent;
        &+.submenu{
          font-size: 14px;
          padding-bottom: 10px;
          .title2{
            padding-left: 87px;
            cursor: pointer;
            line-height: 36px;
            height: 36px;
            &:hover{
              background-color: #eff1f3;
            }
            &.active{
              color:@mcolor;
            }
          }
        }
      }
      i[class^=el-icon-] {
        vertical-align: text-bottom;
        margin-right: 10px;
        width: 40px;
        text-align: center;
        font-size: 28px;
        color: #909399;
      }
    }
    
  }
}
</style>
