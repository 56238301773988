import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)

const pk = process.env.VUE_APP_PK;

const routes = [];

const Login = () => import('./views/Login.vue');
routes.push({ path: '/Login', component: Login, meta: { title:"登录",isLoginPage: true} });

const PageNotFound = () => import('./views/PageNotFound.vue');
routes.push({ path:'*', component: PageNotFound });

const Home = () => import('./views/Home.vue'); // 主体框架
const HomeRoute = { path: '/Home', component: Home, children:[] }; // 左侧菜单
routes.push(HomeRoute);

const HomeMain = () => import('./views/Home/News/List.vue'); // 新闻动态作为主页
HomeRoute.children.push(
	{ path: 'Main', component: HomeMain, meta: { title: "动态管理", icon: "el-icon-document-add", isMenu:true } }
);

const Config = () => import('./views/Home/Config.vue');
HomeRoute.children.push(
	{ path: 'Config', component: Config, meta: { title: "配置管理", icon: "el-icon-s-tools", isMenu:true }}
);


const UserList = () => import('./views/Home/User/List.vue');
HomeRoute.children.push(
	{ path: 'User/List', component: UserList, meta: { title: "用户管理", icon: "el-icon-user-solid", isMenu:true }}
);

const Empty = () => import('./views/Empty.vue'); // 二级路由载体

if(pk == 'APD'){
	const AwardIntro = () => import('./views/Home/Award/Intro.vue');
	// const AwardCategoryList = () => import('./views/Home/Award/Category/List.vue');
	const AwardWorksList = () => import('./views/Home/Award/Works/List.vue');
	// const AwardJudgesList = () => import('./views/Home/Award/Judges/List.vue');
	const AwardJudgesList2 = () => import('./views/Home/Award/Judges/List2.vue');
	const AwardAnnouncementList = () => import('./views/Home/Award/Announcement/List.vue');
	HomeRoute.children.push(
		{ path: 'Award', component: Empty, meta: { title: "比赛管理", icon: "el-icon-star-on", isMenu: true }, children:[
			{ path: 'Intro', component: AwardIntro, meta: { title: "比赛说明", isMenu: true }},
			// { path: 'Category/List', component: AwardCategoryList, meta: { title: "奖项设置", isMenu: true }},
			{ path: 'Works/List', component: AwardWorksList, meta: { title: "作品管理", isMenu: true }},
			// { path: 'Judges/List', component: AwardJudgesList, meta: { title: "顾问编委", isMenu: true }},
			{ path: 'Judges/List2', component: AwardJudgesList2, meta: { title: "顾问编委", isMenu: true }},
			// { path: 'Announcement/List', component: AwardAnnouncementList, meta: { title: "奖项公布", isMenu: true }},
			{ path: '*', component: PageNotFound }, // 二级页面需要补上一个400页面
		]},
		
	);
	
	const HistoryList = () => import('./views/Home/History/List.vue');
	HomeRoute.children.push(
		{ path: 'History/List', component: HistoryList, meta: { title: "历届APD", icon: "el-icon-date", isMenu:true }}
	);
	HomeRoute.children.push({ path:'*', component: PageNotFound });
}
var show = true
if(pk == 'EPA' && show){
	const AwardWorksList = () => import('./views/Home/Award/Works/List.vue');
	const AwardReferendumList = () => import('./views/Home/Award/Referendum/List.vue');
	// const AwardAnnouncementList = () => import('./views/Home/Award/Announcement/List.vue');
	HomeRoute.children.push(
		{ path: 'Award', component: Empty, meta: { title: "比赛管理", icon: "el-icon-star-on", isMenu: true }, children:[
			{ path: 'Works/List', component: AwardWorksList, meta: { title: "作品管理", isMenu: true }},
			{ path: 'Referendum/List', component: AwardReferendumList, meta: { title: "投票记录", isMenu: true }},
			// { path: 'Announcement/List', component: AwardAnnouncementList, meta: { title: "奖项公布", isMenu: true }},
			{ path: '*', component: PageNotFound }, // 二级页面需要补上一个400页面
		]},
		
	);
	
}




const MainRouter = new VueRouter({
  mode: 'hash',
  routes,
})

MainRouter.beforeEach((to, from, next) => {
  console.log(to, from);
  const token = window.localStorage.getItem("token");

  if (token){
    if (to.path == '/' || to.matched.some(record => record.meta.isLoginPage)) {
      next({
        path: '/Home/Main'
      });
    } else {
      if (to.path == '/Home') {
        next({
          path: '/Home/Main'
        });
      }else{
        next();
      }
      
    }
  } else{
    if (to.matched.some(record => record.meta.isLoginPage)) {  
      next();
    } else {
      next({
        path: '/Login',
        query: { redirect: to.fullPath } 
      })
    }
  }
  
});

function scrollAnimation(currentY, targetY) {
  // 计算需要移动的距离
  let needScrollTop = targetY - currentY
  let _currentY = currentY
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10)
    _currentY += dist
    window.scrollTo(_currentY, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      scrollAnimation(_currentY, targetY)
    } else {
      window.scrollTo(_currentY, targetY)
    }
  }, 1)
}

const title = window.document.title.replace("Loading - ","");
MainRouter.afterEach(to => {
  var temp = [];
  if (to.matched){
    to.matched.forEach(m=>{
      if(m.meta && m.meta.title){
        temp.push(m.meta.title);
      }
    });
  }
  window.document.title = temp.length > 0 ? temp.join(" / ") + " - " + title : title;
  scrollAnimation(window.scrollY,0);
})
export default MainRouter;