<template>
  <div class="cover-uploader">
		<CoverWrap v-if="cover" :src="cover.indexOf('.gif')>-1?cover:(cover+'?x-oss-process=image/resize,l_160')" width="160px" height="100px"/>
		<!-- <CoverWrap v-if="cover" :src="cover+'?x-oss-process=image/resize,l_100'" width="160px" height="100px"/> -->
  	<!-- <img v-if="cover" :src="cover" /> -->
		<div v-if="loading" class="loading">
			<img src="@/assets/common/loading_d.gif" >
			上传中,请勿保存..
		</div>
  	<input type="file" ref="cover" @change="doCoverUpload"/>
  </div>
</template>

<script>
export default {
  name: 'cover-uploader',
  props: {
    
  },
  data(){
    // console.log("this.list",this.list);
    return {
      cover:"",
			loading:false
    }
  },
  created () {
    
  },
  methods:{
    setCover(cover){
			this.cover = cover;
		},
		getCover(){
			return this.cover;
		},
		doCoverUpload(){
			this.loading = true;
			this.doUploadFile(this.$refs.cover.files[0],'others/cover/',(err,etag,uploadPathWithHost)=>{
				this.$refs.cover.value=null;
				this.loading = false;
				if(err){this.$message.error("上传失败,请重新上传");return;}
				this.cover = uploadPathWithHost;
				
			});
		},
		doUploadFile(file,uploadPath,cb){
			this.$root.doUploadFile(file,{
				uploadPath
			},cb);
		},
  } 
}
</script>

<style scoped lang="less">
.cover-uploader{
	img {
		width:160px;
		max-height:400px;
		margin-bottom:10px;
		display: block;
	}
	.loading{
		// text-align: center;
		img{
			width:16px;
			height:16px;
			display: inline-block;
			// vertical-align: middle;
			margin-bottom: 0;
		}
	}
}
</style>
