<template>
<div>
  <el-drawer
    :visible.sync="show"
    direction="ltr"
    size="45%"
    :withHeader="false"
    :close-on-press-escape="false"
    :before-close="close">
    <div class="mt-10"></div>
      <el-divider content-position="left">基本内容</el-divider>
			<template v-if="isEdit">
				<el-descriptions class="info-box" title="" :column="3" size="mini" border>
					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-user"></i>
							系统ID
						</template>
						{{formData.id}}
					</el-descriptions-item>
					<el-descriptions-item >
						<template slot="label">
							<i class="el-icon-s-ticket"></i>
							参赛编号
						</template>
						{{$root.createUID(formData.id)}}
					</el-descriptions-item>
					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-message"></i>
							注册邮箱
						</template>
						{{formData.email}}
					</el-descriptions-item>
				</el-descriptions>
				
					<template v-if="$root.pk == 'EPA'">
						<el-descriptions class="info-box" title="" :column="3" size="mini" border>
							<el-descriptions-item v-if="formData.config && formData.config.unitType">
								<template slot="label">
									<i class="el-icon-goblet-square-full"></i>
									参赛组别
								</template>
								{{formData.config.unitType == 1 ? '非专业组':'专业组'}}
							</el-descriptions-item>
							<el-descriptions-item v-if="formData.config && formData.config.groupType">
								<template slot="label">
									<i class="el-icon-location-outline"></i>
									参赛单位
								</template>
								{{formData.config.groupType == 1? '个人参赛':'团队参赛'}}
							</el-descriptions-item>
							<el-descriptions-item v-if="formData.config && formData.config.tel">
								<template slot="label">
									<i class="el-icon-phone"></i>
									联系电话
								</template>
								{{formData.config.tel}}
							</el-descriptions-item>
						
							
							
							
							<template v-if="formData.config && formData.config.memberData && formData.config.memberData.length">
								
								<el-descriptions-item :span="3"   v-for="(item,index) in formData.config.memberData" :key="item.id">
									<template slot="label">
										<template v-if="formData.config.groupType == 1">
											其他信息
										</template>
										<template v-if="formData.config.groupType == 2">
											成员{{index+1}}
										</template>
									</template>
									<div class="">
										<div class="studentCards">
											<el-image
												style="width: 100px; height: 100px"
												:src="item.studentCard" 
												:preview-src-list="[item.studentCard]">
											</el-image>
											<el-image v-if="item.studentCard2"
												style="width: 100px; height: 100px"
												:src="item.studentCard2" 
												:preview-src-list="[item.studentCard2]">
											</el-image>
											<el-image v-if="item.studentCard3"
												style="width: 100px; height: 100px"
												:src="item.studentCard3" 
												:preview-src-list="[item.studentCard3]">
											</el-image>
											<!-- <p class="">学生证件</p> -->
										</div>
										<div>
											<p class="label-box flex middle"><span class="label">姓名：</span>{{item.name}}</p>
											<p class="label-box flex middle"><span class="label">院校：</span>{{item.school}}</p>
											<p class="label-box flex middle"><span class="label">专业：</span>{{item.major}}</p>
										</div>
									</div>
								</el-descriptions-item>
									<!-- <el-descriptions-item :span="3" v-if="formData.config.groupType == 2">
										<template slot="label">
											<i class="el-icon-user"></i>
											成员{{index+1}}
										</template>
									</el-descriptions-item>
									<el-descriptions-item>
										<template slot="label">
											<i class="el-icon-user"></i>
											
										</template>
										{{item.name}}
									</el-descriptions-item> -->
									
								
							
							
							</template>
						</el-descriptions>
							
					</template>
					<template v-if="$root.pk == 'APD'">
						<el-descriptions class="info-box" title="" :column="3" size="mini" border>
							<el-descriptions-item v-if="formData.config && formData.config.unitType">
								<template slot="label">
									
									投稿单位
								</template>
								{{formData.config.unitType}}
							</el-descriptions-item>
							<el-descriptions-item v-if="formData.config && formData.config.unitName" :span="(formData.config && formData.config.unitType=='品牌方投稿')?1:2">
								<template slot="label">
									
									单位名称
								</template>
								{{formData.config.unitName}}
							</el-descriptions-item>
							<el-descriptions-item v-if="formData.config && formData.config.unitTeam">
								<template slot="label">
									
									设计团队名称
								</template>
								{{formData.config.unitTeam}}
							</el-descriptions-item>
							
							<el-descriptions-item v-if="formData.config && formData.config.city">
								<template slot="label">
									
									所在城市
								</template>
								{{formData.config.city}}
							</el-descriptions-item>
							<el-descriptions-item v-if="formData.config && formData.config.wechat">
								<template slot="label">
									
									微信
								</template>
								{{formData.config.wechat}}
							</el-descriptions-item>
							<el-descriptions-item v-if="formData.config && formData.config.website">
								<template slot="label">
									
									官网
								</template>
								{{formData.config.website}}
							</el-descriptions-item>
							
							<el-descriptions-item v-if="formData.config && formData.config.tel">
								<template slot="label">
									
									联系电话
								</template>
								{{formData.config.tel}}
							</el-descriptions-item>
							
							<el-descriptions-item v-if="formData.config && formData.config.address">
								<template slot="label">
									
									联系地址
									</template>
									{{formData.config.address}}
							</el-descriptions-item>
						</el-descriptions>
					</template>
				
				
			
				<el-divider content-position="left">可更改信息</el-divider>
			
			</template>
			
      <el-form :model="formData" label-width="120px" size="small">
				<template v-if="!isEdit">
					<el-form-item label="注册邮箱">
					  <el-input
					    v-model="formData.email" 
							placeholder="请输入用户邮箱"
					    autocomplete="off" 
					    style="width:300px"
							ref="email"
							@change="checkEmail"
					  >
						</el-input>
					</el-form-item>
					
				</template>
        <el-form-item label="登录密码">
          <el-input
            v-model="formData.password" 
						placeholder="请输入用户密码"
            autocomplete="off" 
            style="width:200px"
						ref="password"
					 @change="checkPassword"
          >
					</el-input>
        </el-form-item>
				<el-form-item label="用户类型">
					<el-select v-model="formData.role" placeholder="请选择">
						<el-option label="普通用户" value="NORMAL"></el-option>
						<el-option label="评委" value="JUDGES" v-if="$root.pk == 'APD'"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="参赛类型" v-if="$root.pk == 'EPA'">
					<el-select v-model="formData.config.unitType" placeholder="请选择">
						<el-option label="非专业组" :value="1"></el-option>
						<el-option label="专业组" :value="2"></el-option>
					</el-select>
				</el-form-item>
				
				<template v-if="$root.pk == 'APD' && formData.role == 'JUDGES'">
					
					<el-divider content-position="left">评委配置</el-divider>
					<el-form-item label="所属分组" >
						<el-select v-model="formData.config.groupType" placeholder="请选择" @change="changeGroupType">
							<el-option label="请选择" value=""></el-option>
							<el-option label="编委A组" value="A"></el-option>
							<el-option label="编委B组" value="B"></el-option>
							<el-option label="编委C组" value="C"></el-option>
							<el-option label="学术顾问" value="S"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="评选配置" >
						<template  v-if="formData.config.groupType == 'A'|| formData.config.groupType == 'B'|| formData.config.groupType == 'C'">
							<el-form-item label="推荐设置(初评)" >
								<el-checkbox-group v-model="formData.config.recommondAwardList">
									<el-checkbox :label="item.id"  v-for="item in awardTypeList" :key="item.id">{{item.name}}</el-checkbox>
									<!-- <el-checkbox :label="item.id"  v-for="item in $root.awardTypeList.filter(i=>i.groupType == formData.config.groupType)" :key="item.id">{{item.name}}</el-checkbox> -->
								</el-checkbox-group>
								<div class="mt-5"></div>
								每个类别限制最多
								<el-input-number v-model="formData.config.maxRecommondCount" :min="0" :max="999" size="mini" style="width:90px"></el-input-number>
								个推荐
							</el-form-item>
						</template>
						<el-form-item label="投票设置(终评)"  v-if="formData.config.groupType">
							投票数等于
							<el-input-number :min="0" :max="999" size="mini" v-model="formData.config.needVotedCount" style="width:90px"></el-input-number>
							个才可提交
						</el-form-item>
						
					</el-form-item>
					
					<el-form-item label="初评结果"  v-if="formData.config.groupType == 'A'|| formData.config.groupType == 'B'|| formData.config.groupType == 'C'">
						<el-tabs tab-position="left" style="height:auto;" v-model="firstScoreResultTabName" @tab-click="changefirstScoreResultTab">
							<el-tab-pane :label="item.name" v-for="item in awardTypeList" :key="item.id" :name="item.id.toString()">
								
								<div class="status">状态: 
									<template v-if="currentFirstScoreResult && currentFirstScoreResult.isSubmit">已提交</template>
									<template v-else>未提交</template>
								</div>
								<div class="recommentList" v-if="currentFirstScoreResult && currentFirstScoreResult.isSubmit && currentFirstScoreResult.recommentList">
									推荐作品: 
									<template v-if="currentFirstScoreResultRecommentList.length"><el-tag color="#53C5F5" style="cursor: pointer;color:#000" size="mini" v-for="ritem in  currentFirstScoreResultRecommentList" :key="ritem.id" @click="$root.doWorkView(ritem)">{{$root.createWID(ritem)}}</el-tag></template>
									<template v-else>暂无推荐作品</template>
								</div>
								<div class="recommentList" v-if="currentFirstScoreResult && currentFirstScoreResult.isSubmit && currentFirstScoreResult.recommentList">
									评分作品: 
									<template v-if="currentFirstScoreResultScoreList.length"><el-tag :color="sitem.color" style="cursor: pointer;color:#000" size="mini" v-for="sitem in  currentFirstScoreResultScoreList" :key="sitem.id" @click="$root.doWorkView(sitem)">{{$root.createWID(sitem)}} | {{sitem.score}}分</el-tag></template>
									<template v-else>暂无评分作品</template>
								</div>
							</el-tab-pane>
						</el-tabs>
						<el-button type="warning" @click="doClearFirstScoreResult" :loading="isLoading">重置初评(重置后需保存才能生效)</el-button>
					</el-form-item>
					<el-form-item label="终评结果"  v-if="formData.config.groupType">
						
						<template v-if="secondScoreResult">
							<div class="status">状态:
								<template v-if="secondScoreResult.isSubmit">已提交</template>
								<template v-else>未提交</template>
							</div>
							<div class="recommentList" v-if="secondScoreResult.isSubmit && secondScoreResult.votedList">
								投票作品: 
								<template v-if="secondScoreResult.votedList.length">
									<el-tag color="#F56C6C" style="cursor: pointer;color:#000" size="mini" v-for="ritem in  secondScoreResult.votedList" :key="ritem.id" @click="$root.doWorkView(ritem)">{{$root.createWID(ritem)}}</el-tag></template>
								<template v-else>暂无推荐作品</template>
							</div>
							
							<el-button type="warning" @click="doClearSecondScoreResult" :loading="isLoading">重置终评(重置后需保存才能生效)</el-button>
						</template>
						<template v-else>
							未提交
						</template>
					</el-form-item>
				</template>

        <el-form-item label="">
					<el-divider></el-divider>
          <el-button type="primary" @click="doSubmit" :loading="isLoading">保 存</el-button>
        </el-form-item>

      </el-form>

  </el-drawer>

</div>

</template>

<script>

const defaultData = {
	id:0,
	email:"",
	password:"",
	config:{"groupType":""},
	createAt:"",
	role:"NORMAL"
};
export default {
  name: 'UserEdit',
  components: {
    
  },
  props: {
    data: Object,
    show: Boolean,
		isEdit: Boolean
  },
  data(){
    return {
      formData:{...defaultData},
			isLoading:false,
			editor:null,
			ossConfig:{},
			awardTypeList: this.$root.awardTypeList,
			firstScoreResultTabName:"",
			currentFirstScoreResult:{},
			currentFirstScoreResultRecommentList:[],
			currentFirstScoreResultScoreList:[],
			secondScoreResult:{},
    }
  },
	mounted(){
		
	},
  watch: {
		show(newData){
			
			if(newData){
				this.$nextTick(()=>{
					this.initData()
				})
				
				// this.request.get("/api/v1/frontDeskUser/get?id="+this.data.id)
			}
		}
  },
	
  methods:{
		
		async initData(){
			var data = this.$root.copy(defaultData);
			if(this.data && this.data.id){
				this.isEdit = true;
				data = {
					...data,
					...this.$root.copy(this.data)
				};
				
				if(data.role == 'JUDGES'){
					if(!data.config.groupType){
						data.config.groupType = '';
					}
					
				}
			}
			
			this.$set(this,'secondScoreResult',{});
			this.$set(this,'formData',data);
			
			if(data.role == 'JUDGES'){
				await this.changeGroupType();
				await this.initSecondResult();
			}
			
		},
		checkEmail(){
			if(!this.$root.checkEmail(this.formData.email)){
				// this.formRule.email = false;
				this.$refs.email.focus();
				return false;
			}
			// this.formRule.email = true;
			return true;
		},
		checkPassword(){
			if(!this.$root.checkPassword(this.formData.password)){
				// this.formRule.password = false;
				this.$refs.password.focus();
				return false;
			}
			// this.formRule.password = true;
			return true;
		},
		
    close(){
      this.$emit('update:show',false);
    },
		doClearFirstScoreResult(){
			this.formData.config.firstScoreList = [];
			this.formData.config.firstScoreResult = [];
			
			this.$set(this,'currentFirstScoreResult',{});
			this.$set(this,'currentFirstScoreResultRecommentList',[]);
			this.$set(this,'currentFirstScoreResultScoreList',[]);
			
		},
		doClearSecondScoreResult(){
			this.formData.config.secondScoreList = [];
			this.formData.config.secondScoreResult = [];
			
			this.$set(this,'secondScoreResult',{});
			
		},
		resetResultAwardTypeList(){
			
			if(!this.formData.config.recommondAwardList){
				let groupType = this.formData.config.groupType;
				
				this.formData.config.recommondAwardList = this.$root.awardTypeList.filter(item=>item.groupType == groupType).map(item=>item.id);
				this.$set(this.formData.config,'recommondAwardList',this.formData.config.recommondAwardList);
			}
			this.awardTypeList = this.$root.awardTypeList.filter(item=>this.formData.config.recommondAwardList.includes(item.id));
			if(this.formData.config.recommondAwardList && this.formData.config.recommondAwardList.length){
				if(!this.formData.config.recommondAwardList.includes(parseInt(this.firstScoreResultTabName))){
					this.firstScoreResultTabName = this.formData.config.recommondAwardList.sort()[0].toString();
				}
			} else{
				this.firstScoreResultTabName = "0";
			}
			this.changefirstScoreResultTab();
		},
		async changeGroupType(groupType){
			if(!groupType){
				groupType = this.formData.config.groupType;
			}
			if(groupType == 'A' || groupType == 'B' || groupType == 'C'){
				// this.resetResultAwardTypeList();
				this.awardTypeList = this.$root.awardTypeList.filter(item=>this.$root.CONFIG['groupType'+groupType].includes(item.id));
				console.log("this.awardTypeList",this.awardTypeList);
				if(!this.formData.config.firstScoreList){
					this.$set(this.formData.config,'firstScoreList',[])
				}
				if(!this.formData.config.firstScoreResult){
					this.$set(this.formData.config,'firstScoreResult',[])
				}
				if(!this.formData.config.recommondAwardList){
					this.$set(this.formData.config,'recommondAwardList',[])
				}
				if(!this.formData.config.maxRecommondCount){
					this.$set(this.formData.config,'maxRecommondCount',10)
				}
				
			} else {
				this.awardTypeList = this.$root.awardTypeList;
			}
			
			if(!this.formData.config.needVotedCount){
				this.$set(this.formData.config,'needVotedCount',20)
			}
			if(!this.formData.config.secondScoreList){
				this.$set(this.formData.config,'secondScoreList',[])
			}
			if(!this.formData.config.secondScoreResult){
				this.$set(this.formData.config,'secondScoreResult',[])
			}
			
			if(this.awardTypeList.length){
				this.firstScoreResultTabName = this.awardTypeList[0].id.toString();
				await this.changefirstScoreResultTab();
			}
		},
		async changefirstScoreResultTab(){
			let aid = parseInt(this.firstScoreResultTabName);
			let result = this.getFirstScoreResult(aid);
			let currentFirstScoreResult = {};
			let currentFirstScoreResultRecommentList = [];
			let currentFirstScoreResultScoreList = [];
			if(result){
				currentFirstScoreResult = result;
				if(result.recommentList && result.recommentList.length){
					let recommentListResult = await this.request.get(`/api/v1/awardWork/paging?ids=${result.recommentList.join(',')}`)
					if(recommentListResult){
						currentFirstScoreResultRecommentList = recommentListResult.rows || [];
						currentFirstScoreResultRecommentList.forEach(item =>{
							try{
								item.content = JSON.parse(item.content);
							}catch(e){
								item.content = {};
							}
							this.$root.initAwardType(item);
						});
					}
					
					
				}
				if(result.scoreList && result.scoreList.length){
					let scoreListResult = await this.request.get(`/api/v1/awardWork/paging?ids=${result.scoreList.map(item=>item.wid).join(',')}`)
					if(scoreListResult){
						currentFirstScoreResultScoreList = scoreListResult.rows || [];
						currentFirstScoreResultScoreList.forEach(item =>{
							try{
								item.content = JSON.parse(item.content);
							}catch(e){
								item.content = {};
							}
							this.$root.initAwardType(item);
							
							item.score = 0;
							item.color = '';
							let target = result.scoreList.find(item2=>item2.wid == item.id);
							
							if(target){
								item.score = target.score;
								if(item.score == 7){
									item.color = '#8BF044';
								} else if(item.score == 5){
									item.color = '#E3FF2C';
								} else if(item.score == 3){
									item.color = '#FCFB61';
								} else if(item.score == 0){
									item.color = '#DEDEDE';
								}
							}
						});
					}
				}
			}
			this.$set(this,'currentFirstScoreResult',currentFirstScoreResult);
			this.$set(this,'currentFirstScoreResultRecommentList',currentFirstScoreResultRecommentList);
			this.$set(this,'currentFirstScoreResultScoreList',currentFirstScoreResultScoreList);
			
		},
		getFirstScoreResult(aid){
			let firstScoreResult = this.formData.config.firstScoreResult || [];
			let target = firstScoreResult.find(item => item.aid == aid);
			return target;
		},
		async initSecondResult(){
			if(!this.formData.config.secondScoreResult){return ;}
			var secondScoreResult = this.formData.config.secondScoreResult.find(item=>item.aid == 0);
			if(!secondScoreResult){return ;}
			if(!secondScoreResult.votedList){secondScoreResult.votedList = [];}
			if(secondScoreResult.votedList.filter(item=>item.voteType==1).length > 0){
				let scoreListResult = await this.request.get(`/api/v1/awardWork/paging?ids=${secondScoreResult.votedList.filter(item=>item.voteType==1).map(item=>item.wid).join(',')}`);
				if(scoreListResult){
					let votedList = scoreListResult.rows || [];
					votedList.forEach(item =>{
						try{
							item.content = JSON.parse(item.content);
						}catch(e){
							item.content = {};
						}
						this.$root.initAwardType(item);
					});
					secondScoreResult.votedList = votedList;
				}
				
			}
			
			this.$set(this,'secondScoreResult',secondScoreResult);
			
			
		},
    async doSubmit(){
			var updateWork = false
			var params = {password:this.formData.password,role:this.formData.role};
			if(params.role == 'JUDGES'){
				params.config = JSON.stringify(this.formData.config);
			}
			if(this.$root.pk=='EPA'){
				params.config = JSON.stringify(this.formData.config);
				if(this.data.config && this.data.config.unitType && (this.data.config.unitType != this.formData.config.unitType)){
					updateWork = true;
					console.log(updateWork);
				}
			}
			var url = '/api/v1/frontDeskUser/update';
			// 检测内容
			if(this.isEdit){
				params.id = this.formData.id;
				if(!params.password){
					delete params.password
				} else if(!this.checkPassword()){
					return ;
				}
			} else {
				url = '/api/v1/frontDeskUser/create';
				params.email = this.formData.email;
				if(!this.checkEmail() || !this.checkPassword()){
					return ;
				}
			}
			
			this.isLoading = true;
			
			var result = await this.request.post(url,params);
			if(updateWork){
				let listResult = await this.request.get(`/api/v1/awardWork/paging?pageSize=99&page=1&userIds=${this.data.id}`)
				let list = (listResult && listResult.rows) || [];
				// console.log(list,this.formData.config.unitType);
				list.forEach(async item=>{
					await this.request.post('/api/v1/awardWork/manage/update',{
						id:item.id,
						unitType:this.formData.config.unitType
					});
				});
				
			}
      this.isLoading = false;
			this.$message.success("保存成功");
			this.close();
			
    }
  },
}
</script>

<style scoped lang="less">
.info-box{
	padding:10px 20px 20px;
}
#edit-content{
	width:90%;
}
.label-box{
	margin-left:10px;
	.label{
		display:inline-block;
		// width:80px;
		// text-align: right;
		line-height: 2;
	}
}
.studentCards{
	margin-top:10px;
	.el-image{
		margin-left:10px;
	}
}
</style>
