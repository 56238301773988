<template>
  <transition name="fade">
    <div class="loading" v-show="loading" key="loading">
			<div class="img"><i class="el-icon-loading"></i></div>
      <!-- <img class="bg" alt="loading" v-show="bgrun" :onload="runbg()" src="~@/assets/common/red-bg.png"/> -->
      <!-- <img class="img" alt="loading" src="~@/assets/common/logo.png"/> -->
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loading: Boolean
  },
  data(){
    return {
      bgrun:false
    }
  },
  methods:{
    runbg(){
      this.bgrun = true;   
    }
  } 
}
</script>

<style scoped>
.loading{
  position: fixed;
  z-index: 1001;
  background-color: #fff;
  left:0;
  right:0;
  top:0;
  bottom:0;
  text-align: center;
	font-size:30px;
}

.img,.bg{
  width:150px; 
  margin:100px auto;
	color:#ee8222;
}
.bg{
  position: absolute;
  margin-left:0px;
  z-index:-1;
  opacity: 0;
  animation:run 4s ease-in ;
  animation-delay:1s;
  animation-iteration-count:3;
  /* animation-fill-mode:forwards; */
}
@keyframes run{
  20%{opacity: 1;}
  100%{opacity: 1;transform: rotate(-360deg);}
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
