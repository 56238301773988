import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = true

Vue.prototype.L = (text)=>{return text;}

// 挂载路由
import router from './router'
// 处理request异常
request.interceptors.response.use( 
  (data) => {
    if(data && data.status !=1 && data.msg){
      main.$message({
        message: data.msg,
        type: 'warning'
      });
      // if(data.status == 3 || data.status == 4){
      //   if(main.$route.fullPath != '/Login'){
      //     main.$router.push('/Login');
      //   }
      //   return false;
      // }
      
      return false;
    }
    return data.data || data;
  }, 
  (error) => {
    console.error(error);
  }
);

// 挂载UI
import Element from './plugins/element'
Vue.use(Element,{zIndex: 30000 })
import './common.less';

// 挂载组件
import CoverWrap from './components/CoverWrap.vue'
Vue.component('CoverWrap', CoverWrap);

// 全局图片预览器
import ImagesViewer from './components/ImagesViewer.vue';
Vue.component('ImagesViewer', ImagesViewer);

// 挂载请求器
import request from './plugins/request'
Vue.prototype.request = request;

// 混入全局辅助方法
import Common from './common'

// 启动
var main = new Vue({
  render: h => h(App),
  router,
  // components: { Avatar },
  mixins: [Common]
})
// .$mount('#app')