<template>
  <div ref="cover" class="cover" :style="{width,height}">
		<div class="wrap flex middle center">
			<img class="loading" v-if="loading" src="@/assets/common/loading_d.gif" />
			<div class="canvas" ref="canvas" v-if="src && isCavans"></div>
			<img class="img" v-if="src && !isCavans" v-show="src1" :src="src1" :style="iStyle" @load="loaded"/>
		</div>
	</div>
</template>

<script>
export default {
  name: 'Cover',
  props: {
    src:{
			default:"",
			type:String,
		},
		width:{
			default:"100%",
			type:String,
		},
		height:{
			default:"100%",
			type:String,
		},
		cavans:{
			default:false,
			type:Boolean,
		},
		
  },
  data(){
		let src1 = this.src;
    return {
      loading:true,
			isCavans:false,
			iStyle:"",
			src1
    }
  },
	async mounted(){
		if(this.cavans){
			this.isCavans = true;
			this.$nextTick(()=>{
				this.createCanvas();
			})
		}else {
			if(this.width){
				this.iStyle = "width:" + this.width;
			}
			this.loadImg()
		}
	},
	
  methods:{
		loaded(){
			this.loading = false;
		},
		loadImg(){
			console.log("loadImg");
			var img = new Image();
			img.onload = (event)=>{
				var r1 = this.$refs.cover.clientWidth/this.$refs.cover.clientHeight;
				var r2 = img.width/img.height;
				if(r1 >= r2){
					if(this.$refs.cover.clientWidth > img.width){
						this.iStyle = "width:" + img.width + "px";
					} else {
						this.iStyle = "width:100%;";
					}
				} else {
					if(this.$refs.cover.clientHeight > img.height){
						this.iStyle = "height:" + img.height + "px";
					} else {
						this.iStyle = "height:100%;";
					}
				}
				// console.log(this.$refs.cover.clientWidth/this.$refs.cover.clientHeight);
				// console.log(img.width/img.height);
				this.loading = false;
				
			}
			
			img.src = this.src1;
		},
		
		createCanvas(){
			
			let content = this.$refs.canvas;
			if(!content){
				this.isCavans = false;
				return ;
			}
			let canvas = document.createElement('canvas')
			var ctx = canvas.getContext('2d')
			let image = new Image()
			image.src = this.src;
			image.onload = ()=>{
				if(image.complete){
					//  根据图像重新设定了canvas的长宽
					let width = this.$refs.cover.clientWidth;
					let height = this.$refs.cover.clientHeight;
					canvas.setAttribute("width",width)
					canvas.setAttribute("height",height)
					//  绘制图片
					ctx.drawImage(image,0,0,width,height)
					content.appendChild(canvas);
					this.loaded = false;
				}
			}
		},
  } 
}
</script>

<style scoped lang="less">
.cover{
	position: relative;
	overflow: hidden;
	width:100%;
	height: 40px;
	.wrap{
		background-color:#DCDDDD;
		height:100%;
		width:100%;
		.loading,.img,canvas{
			position: absolute;
			top:50%;left:50%;
			transform: translate(-50%,-50%);
		}
		.loading{z-index:1}
		.canvas{
			width: 100%;
			min-height: 100%;
			z-index:2;
		}
	}
}
</style>
