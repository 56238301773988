import { render, staticRenderFns } from "./JudgesEdit.vue?vue&type=template&id=3281ab78&scoped=true&"
import script from "./JudgesEdit.vue?vue&type=script&lang=js&"
export * from "./JudgesEdit.vue?vue&type=script&lang=js&"
import style0 from "./JudgesEdit.vue?vue&type=style&index=0&id=3281ab78&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3281ab78",
  null
  
)

export default component.exports