<template>
  <div id="login">
    <div class="login-box">
      <div class="logo flex middle ">
				设计比赛 · 管理后台
        <!-- <img src="~@/assets/common/loading.gif" />
        <img src="~@/assets/common/logo_long.png" /> -->
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item label="" prop="username">
          <el-input v-model="ruleForm.username" placeholder="请输入登录账号/手机号" autofocus></el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input type="password" v-model="ruleForm.password" placeholder="请输入登录密码" @keyup.enter.native="submitForm('ruleForm')"></el-input>
        </el-form-item>
        
        <el-form-item style="margin-top:40px;">
          <el-button type="primary" @click="submitForm('ruleForm')" style="width:154px" :loading="loging">登录</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
  
</template>

<script>

export default {
  name: 'Login',
  components: {
    
  },
  data(){

    
    return {
      loging:false,
      ruleForm:{
        username:window.localStorage.getItem("username")||'',
        password:''
      },
      rules:{
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
      }
    }
  },
  mounted () {
    
  },
  methods: {
    submitForm(){
      if(this.loging){return ;}
      this.loging = true;

      this.request.post('/api/v1/manageUser/login',this.ruleForm).then(data=>{
        this.loging = false;
        if(!data){return ;}
        if(data.token && data.username){
          window.localStorage.setItem("token",data.token);
          window.localStorage.setItem("username",data.username);
          this.request.defaults.headers.common["token"] = data.token;
          this.$notify({
            title: '登录成功',
            message: '正在转入管理后台..',
            type: 'success',
            duration:1000
          });
          setTimeout(() => {
            this.$router.push('/Home/Main');
          }, 1000);
          return ;
        }else {
          this.$message.error('登录异常,请稍后再试.');
        }
        return data;
      })

      
      
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style lang="less" scoped>
#login{
  height:100%;width: 100%;
  text-align: center;
	&::after,&::before{
		// filter: blur(.5px);
		// opacity: 0.9;
		position: absolute;
	}
  &::after{
    content:" ";
    background:url("~@/assets/login/bg.jpg") no-repeat center bottom/cover fixed;
    top:0;left:0;right:0;
    height: 100%;
    z-index: 1;
  }
	
	&::before{
    content:" ";
    background:url("~@/assets/login/bg2.png") no-repeat center center/100% ;
		background-size: 100%;
    bottom:0;left:0;right:0;
    height:90px;
		width:260px;
    z-index: 2;
	}
	
  .login-box{
    height:260px;
    width:300px;
    position: absolute;
    top:50%;
    margin-top:-170px;
    // left:50%;
    margin-left:120px;
    background-color: #fff;
    padding:20px;
    border-radius: 10px;
    z-index:2;
		box-shadow: 2px 2px 6px rgba(0,0,0,.3);
    .logo {
      width:100%;
			padding-left:6px;
      margin-bottom:15px;
			font-size:18px;
			font-weight: bold;
      img{
        width:100px;
        width:40%;
        margin:0 5%;
        // margin:0px auto 20px;
      }
    }
  }

  .el-button.el-button--default:not(.is-circle) {
    font-weight: normal;
    background-color:#f6f7f8;
    color:#1a1b1c;
    border-color: #e3e4e5;
    padding: 0 16px;
    min-width: 96px;
    line-height: 2.45;
  }

}


</style>