<template>
  <div class="form-box">
    <el-form ref="form" :model="formData" label-width="100px">
      
      <el-form-item label="当前进行" v-if="$root.pk == 'APD'">
        <el-select v-model="formData.mainAward" placeholder="请选择当前进行中的赛事" @change="doChangeMainAward">
          <el-option label="休赛期" value=""></el-option>
          <el-option :label="item.name" :value="item.id" v-for="(item) in $root.awardStageList" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开放注册">
        <el-switch v-model="formData.onRegister"></el-switch>
      </el-form-item>
      <el-form-item label="开放投稿">
        <el-switch v-model="formData.onSubmit" :disabled="formData.mainAward===''"></el-switch>
      </el-form-item>
			<el-form-item label="创作年份" class="yearBox">
        <el-tag
          :key="year"
          v-for="year in formData.yearList"
          closable
          :disable-transitions="false"
          @close="removeYear(year)">
          {{year}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="yearInputVisible"
          v-model="yearInputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="confirmYearInput"
          @blur="confirmYearInput"
        >
        </el-input>
        <el-button v-else class="button-new-tag" type="success" size="small" @click="showYearInput">+ 添加选项</el-button>
      </el-form-item>
			<el-form-item label="编委配置" v-if="$root.pk == 'APD'">
			  <el-button type="primary" size="small" @click="showGroupTypeEdit = true">编辑</el-button>
			</el-form-item>
			<el-form-item label="额外允许提交" v-if="$root.pk == 'EPA'">
			  <el-input v-model="formData.allowSubmitList"></el-input>
			</el-form-item>
			<el-form-item label="开放编辑" v-if="$root.pk == 'EPA'">
			  <el-switch v-model="formData.onEdit" :disabled="formData.mainAward===''"></el-switch>
			</el-form-item>
			<el-form-item label="开放投票" v-if="$root.pk == 'EPA'">
			  <el-switch v-model="formData.onVote" :disabled="formData.mainAward===''"></el-switch>
			</el-form-item>
			<el-form-item label="额外允许编辑" v-if="$root.pk == 'EPA'">
			  <el-input v-model="formData.allowEditList"></el-input>
			</el-form-item>
			<el-form-item label="关闭提示语" v-if="$root.pk == 'EPA'">
			  <el-input
			    type="textarea"
			    :rows="2"
			    placeholder="请输入内容"
			    v-model="formData.closeTips">
			  </el-input>
			</el-form-item>
			<el-form-item label="允许删除作品" v-if="$root.pk == 'EPA'">
			  <el-switch v-model="formData.onDelete"></el-switch>
			</el-form-item>
			
			<template v-if="$root.pk == 'APD'">
				<el-form-item label="评审阶段">
				  <el-select v-model="formData.editorialStage" placeholder="请选择当前评审阶段">
				    <!-- <el-option label="无" value=""></el-option> -->
				    <el-option label="初评阶段" :value="1"></el-option>
				    <el-option label="终评阶段" :value="2"></el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="首页轮播图">
				  <div v-for="(item,index) in formData.index.slider" :key="item.id">
				  	<div class="flex middle">
				  		<div style="width:180px;">
								<UploadBox ref="uploadBox"/>
							</div>
				  		<div style="margin-left: 10px;">
								<el-input size="mini" v-model="item.linkUrl" placeholder="请输入http或/#/开头的跳转链接 留空不跳转" style="width:270px"></el-input><br/>
								<el-button size="mini" type="warning" v-if="formData.index.slider.length>1" @click="doDeleteSlider(formData.index.slider,index)">删除</el-button>
							</div>
				  	</div>
						<div class="mt-20"></div>	
						<div>
							<el-button size="mini" v-if="index==formData.index.slider.length-1" @click="doAddSlider(formData.index.slider)">新增</el-button>
						</div>
				  </div>
						<!-- <el-button size="mini" @click="createSliderData">测试保存</el-button> -->
				</el-form-item>
				<el-form-item label="首页3栏图">
				  <div v-for="(item,index) in formData.index.list" :key="item.id">
				  	<div class="flex middle">
				  		<div style="width:180px;">
								<UploadBox ref="uploadBox2"/>
							</div>
				  		<div style="margin-left: 10px;">
								<el-input size="mini" v-model="item.linkUrl" placeholder="请输入http或/#/开头的跳转链接 留空不跳转" style="width:270px"></el-input><br/>
								<el-input size="mini" v-model="item.title1" placeholder="标题1" style="width:270px"></el-input><br/>
								<el-input size="mini" v-model="item.title2" placeholder="标题2" style="width:270px"></el-input><br/>
								<el-button size="mini" type="warning" v-if="formData.index.list.length>1" @click="doDeleteSlider(formData.index.list,index)">删除</el-button>
							</div>
				  	</div>
						<div class="mt-20"></div>	
				  </div>
					
					<div>
						<el-button size="mini" @click="doAddSlider(formData.index.list)">新增</el-button>
					</div>
						<!-- <el-button size="mini" @click="createSliderData">测试保存</el-button> -->
				</el-form-item>
			</template>
			
			<template v-if="$root.pk == 'EPA'">
				<el-form-item label="首页图">
				  <div>
				  	<div class="flex middle">
				  		<div style="">
								<UploadBox ref="uploadBox_index_pc"/>
								<p>PC端</p>
							</div>
							<div style="">
								<UploadBox ref="uploadBox_index_h5"/>
								<p>移动端</p>
							</div>
				  	</div>
						<div class="mt-20"></div>	
				  </div>
						<!-- <el-button size="mini" @click="createSliderData">测试保存</el-button> -->
				</el-form-item>
				
				<el-form-item label="简介图">
					<div class="flex top">
						<div>
							<div v-for="(item,index) in formData.intro.PC" :key="item.id">
								<div class="mb-20">
									<div style="border:1px solid #eee">
										<UploadBox ref="uploadBox_intro_pc"/>
										<p>PC端{{index+1}} <el-link type="danger" v-if="formData.intro.PC.length>1" @click="doDeleteSlider(formData.intro.PC,index)">删除</el-link></p>
									</div>
									<!-- <div style="margin-left: 10px;">
										<el-button size="mini" type="warning" >删除</el-button>
									</div> -->
								</div>
							</div>
							<div>
								<div class="mt-10"></div>	
								<el-button size="mini" @click="doAddSlider(formData.intro.PC)">新增</el-button>
							</div>
						</div>
						<div style="margin-left:10px;">
							<div v-for="(item,index) in formData.intro.H5" :key="item.id">
								<div class="mb-20">
									<div style="border:1px solid #eee">
										<UploadBox ref="uploadBox_intro_h5"/>
										<p>移动端{{index+1}} <el-link type="danger" v-if="formData.intro.H5.length>1" @click="doDeleteSlider(formData.intro.H5,index)">删除</el-link></p></div>
									<!-- <div style="margin-left: 10px;">
										<el-button size="mini" type="warning" v-if="formData.intro.H5.length>1" @click="doDeleteSlider(formData.intro.H5,index)">删除</el-button>
									</div> -->
								</div>
							</div>
							<div>	
								<div class="mt-20"></div>	
								<el-button size="mini" @click="doAddSlider(formData.intro.H5)">新增</el-button>
							</div>
						</div>
					</div>
						<!-- <el-button size="mini" @click="createSliderData">测试保存</el-button> -->
				</el-form-item>
			</template>
			
      <el-form-item>
        <el-button type="primary" @click="doSubmit" :loading="loading">保存(立即生效)</el-button>
      </el-form-item>
    </el-form>
		
		<GroupTypeEdit :show.sync="showGroupTypeEdit"/>
  </div>
</template>

<script>
import UploadBox from "/src/components/UploadBox.vue"
import GroupTypeEdit from '@/components/GroupTypeEdit.vue';
const ZDID = 'CONFIG';
export default {
  name: 'Home-Config',
  components: {
    UploadBox,
		GroupTypeEdit
  },
  data(){
		var imagesObj = {};
		if(this.$root.pk == 'APD'){
			imagesObj = {index:{slider:[],list:[]}}
		} else if(this.$root.pk == 'EPA'){
			imagesObj = {
				index:{PC:{},H5:{}},
				intro:{PC:[{}],H5:[{}]}
			}
		}
    return {
			loading:false,
			yearInputVisible:false,
			yearInputValue:'',
      formData: {
				mainAward: '',
				editorialStage:1,
				onRegister: true,
				onSubmit: true,
				onEdit: true,
				onVote:true,
				onDelete:true,
				closeTips: "",
				allowEditList:"",
				allowSubmitList:"",
				...imagesObj
			},
			showGroupTypeEdit:false
    };
  },
  async mounted () {
		// this.request.zd.create(ZDID,{
		// 	mainAward: '',
		// 	onRegister: true,
		// 	onSubmit: true,
		// });
		var configs = await this.request.zd.get(ZDID);
		if(this.$root.pk == 'APD'){
			this.formData = {index:{slider:[{id:this.$root.createId(),linkUrl:"",path:""}],list:[{id:this.$root.createId(),linkUrl:"",path:"",title1:"",title2:""}]},yearList:[],...configs}
			if(!this.formData.editorialStage){
				this.formData.editorialStage = 1;
			}
			// this.$set(this.formData.index,'list',[]);
		}
		if(this.$root.pk == 'EPA'){
			this.formData = {index:{
				PC:{id:this.$root.createId(),linkUrl:"",path:""},
				H5:{id:this.$root.createId(),linkUrl:"",path:""},
			},intro:{
				PC:[{id:this.$root.createId(),linkUrl:"",path:""}],
				H5:[
					{id:this.$root.createId(),linkUrl:"",path:""},
					{id:this.$root.createId(),linkUrl:"",path:""},
				],
			},...configs}
		}
    
		
		this.$nextTick(()=>{
			if(this.$root.pk == 'APD'){
				if(this.formData.index.slider.length){
					this.formData.index.slider.forEach((item,index)=>{
						
						if(item.path){
							this.$refs.uploadBox[index].setCover(item.path)
						}
						
					})
				}
				
				if(this.formData.index.list.length){
					this.formData.index.list.forEach((item,index)=>{
						
						if(item.path){
							this.$refs.uploadBox2[index].setCover(item.path)
						}
						
					})
				}
			}
			
			if(this.$root.pk == 'EPA'){
				
				if(this.formData.index.PC && this.formData.index.PC.path){
					this.$refs.uploadBox_index_pc.setCover(this.formData.index.PC.path)
				}
				if(this.formData.index.H5 && this.formData.index.H5.path){
					this.$refs.uploadBox_index_h5.setCover(this.formData.index.H5.path)
				}
				if(this.formData.intro.PC.length){
					this.formData.intro.PC.forEach((item,index)=>{
						
						if(item.path){
							this.$refs.uploadBox_intro_pc[index].setCover(item.path)
						}
						
					})
				}
				if(this.formData.intro.H5.length){
					this.formData.intro.H5.forEach((item,index)=>{
						
						if(item.path){
							this.$refs.uploadBox_intro_h5[index].setCover(item.path)
						}
						
					})
				}
			}
		})
		
  },
  methods: {
		removeYear(tag){
			this.formData.yearList.splice(this.formData.yearList.indexOf(tag), 1);
		},
		showYearInput(){
			this.yearInputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		confirmYearInput(){
			let yearInputValue = this.yearInputValue;
			if (yearInputValue) {
				this.formData.yearList.push(yearInputValue);
			}
			this.yearInputVisible = false;
			this.yearInputValue = '';
		},
		doChangeMainAward(val){
			if(!val){
				this.formData.onRegister = false;
				this.formData.onSubmit = false;
				this.formData.onEdit = false;
			}
		},
		doAddSlider(list){
			list.push({id:this.$root.createId(),linkUrl:"",path:"",title1:"",title2:""});
		},
		doDeleteSlider(list,index){
			list.splice(index,1);
		},
		createSliderData(ref,targetList){
			var list = [];
			ref.forEach((target,index)=>{
				let path = target.getCover();
				if(path){
					var item = targetList[index];
					let data = this.$root.copy(item);
					data.path = path;
					if(!data.id){
						data.id = this.$root.createId();
					}
					if(targetList[index].linkUrl){
						data.linkUrl = targetList[index].linkUrl;
					}
					list.push(data)
				}
			});
			console.log(list);
			return list;
		},
    async doSubmit() {
			this.loading = true;
			var params = this.$root.copy(this.formData);
			if(this.$root.pk == 'APD'){
				params.index.slider = this.createSliderData(this.$refs.uploadBox,this.formData.index.slider);
				params.index.list = this.createSliderData(this.$refs.uploadBox2,this.formData.index.list);
			}
			if(this.$root.pk == 'EPA'){
				params.index.PC = this.createSliderData([this.$refs.uploadBox_index_pc],[this.formData.index.PC])[0];
				params.index.H5 = this.createSliderData([this.$refs.uploadBox_index_h5],[this.formData.index.H5])[0];
				params.intro.PC = this.createSliderData(this.$refs.uploadBox_intro_pc,this.formData.intro.PC);
				params.intro.H5 = this.createSliderData(this.$refs.uploadBox_intro_h5,this.formData.intro.H5);
			}
				
			// console.log(params);
			await this.request.zd.update(ZDID,params);
			this.loading = false;
			this.$message({
				type: 'success',
				message: '保存成功!'
			});
		}
  }
}
</script>

<style scoped lang="less">
.yearBox{
	.el-tag + .el-tag {
		margin-left: 10px;
	}
	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}
	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}
}
</style>