<template>
<div>
  <el-drawer
    :visible.sync="show"
    direction="rtl"
    size="842px"
    :withHeader="false"
    :close-on-press-escape="false"
    :before-close="close">
    <div class="mt-10"></div>
      <el-divider content-position="left">基本内容</el-divider>
			<template v-if="isEdit">
				<el-form :model="formData" label-width="120px" size="small">
					<el-form-item label="类别">
						<el-select 
							v-model="formData.content.awardType"
							placeholder="请选择" 
							@change="doAwardTypeChange"
							style="width:300px">
							<el-option :label="item.name" :value="item.id" v-for="item in $root.awardTypeList" :key="item.id"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="作品标题">
					  <el-input
					    v-model="formData.content.title" 
							placeholder=""
					    autocomplete="off" 
					    style="width:300px"
							ref="title"
					  >
						</el-input>
					</el-form-item>
					<el-form-item label="作品描述">
					  <el-input
					    v-model="formData.content.description" 
							placeholder=""
							type="textarea"
							rows="6"
					    autocomplete="off" 
					    style="width:90%"
							ref="title"
					  >
						</el-input>
					</el-form-item>
					<template v-if="$root.pk == 'EPA'">
						
						<el-form-item label="作者">
						  <el-input
						    v-model="formData.content.creators" 
								placeholder=""
						    autocomplete="off" 
						    style="width:90%"
								ref="title"
						  >
							</el-input>
						</el-form-item>
							
					</template>
					<template v-if="$root.pk == 'APD'">
						<el-form-item label="主创人员名单">
							<div v-for="item in formData.content.creators" :key="item.key">
								<div v-if="isShowAllcreators || (!isShowAllcreators&&item.value)">
									<el-input
										v-model="item.value" 
										placeholder=""
										autocomplete="off" 
										style="width:90%"
										ref="creators"
										
									>
										<template slot="prepend">
											<div class="creators_prepend flex center">
												<div>{{item.key.split("|")[0]}}</div>
												<div>{{item.key.split("|")[1]}}</div>
											</div>
										</template>
									</el-input>
									<div class="mt-10"></div>
								</div>
							</div>
							<div><el-button size="mini" :icon="isShowAllcreators?'el-icon-arrow-up':'el-icon-arrow-down'" @click="isShowAllcreators=!isShowAllcreators">{{isShowAllcreators?"隐藏未填项":"显示未填项"}}</el-button></div>
						</el-form-item>
						
						<el-form-item label="作品图片">
							<div class="upload-box"><UploadBox4Work ref="upload_images" :uid="0" accept="image/png,image/jpeg,image/jpg" :size="20"></UploadBox4Work></div>
						</el-form-item>
						
						<el-form-item label="作品视频" v-show="isShowVideo">
							<div class="upload-box"><UploadBox4Work ref="upload_videos" :max="2" :uid="0" accept="audio/*,video/*,application/ogg,audio/ogg,image/gif" :size="100"></UploadBox4Work></div>
						</el-form-item>
						
						
					</template>
					
					<el-form-item label="">
					  <el-button type="primary" @click="doSubmit" :loading="isLoading">保 存</el-button>
					</el-form-item>
				</el-form>
			
			</template>
      
  </el-drawer>

</div>

</template>

<script>
import UploadBox4Work from '@/components/UploadBox4Work';
const defaultData = {
	id:0,
	content:{},
	
};
export default {
  name: 'WorkEdit',
  components: {
    UploadBox4Work
  },
  props: {
    data: Object,
    show: Boolean,
		isEdit: Boolean
  },
  data(){
    return {
      formData:{...defaultData},
			isLoading:false,
			editor:null,
			isShowVideo:false,
			ossConfig:{},
			isShowAllcreators:false
    }
  },
	mounted(){
		
	},
  watch: {
		show(newData){
			
			if(newData){
				this.$nextTick(()=>{
					this.initData()
				})
				
				// this.request.get("/api/v1/frontDeskUser/get?id="+this.data.id)
			}
		}
  },
	
  methods:{
		doAwardTypeChange(awardType){
			let awardTarget = this.$root.awardTypeList.find(item => item.id == awardType);

			if(awardTarget.config && awardTarget.config.video){
				this.isShowVideo = true;
			} else {
				this.isShowVideo = false;
			}
		},
		initData(){
			// console.log(this.$parent.showUserById(this.data.userId))
			this.isShowAllcreators = false;
			var data = this.$root.copy(defaultData);
			if(this.data && this.data.id){
				this.isEdit = true;
				data = {
					...data,
					...this.$root.copy(this.data)
				};
				
				if(this.$root.pk == 'APD'){
					let creators = this.$root.copy(data.content.creators);
					data.content.creators = this.$root.copy(this.$root.creatorList.map(item=>{ return {key:item.code+"|"+item.name,value:""} }));
					
					let awardType = data.content.awardType;
					let awardTarget = this.$root.awardTypeList.find(item => item.id == awardType);
					
					if(awardTarget && awardTarget.config && awardTarget.config.video){
						this.isShowVideo = true;
					} else {
						this.isShowVideo = false;
					}
					
					
					creators.forEach(item =>{
						for(let i=0,l=data.content.creators.length;i<l;i++){
							if(item.key == data.content.creators[i].key){
								data.content.creators[i].value = item.value;
								break;
							}
						}
					});
					
				}
				
				
			}
			
			console.log('data',data);
			
			this.$set(this,'formData',data);
			console.log('this.formData',this.formData);
			
			
			this.$nextTick(()=>{
				
				this.$refs.upload_images.updateList(this.formData.content.images);
				if(this.isShowVideo){
					this.$refs.upload_videos.updateList(this.formData.content.videos);
				} else {
					this.$refs.upload_videos.updateList([]);
				}
				// this.$refs.upload_videos.updateList(this.formData.content.videos);
				
			})
			
		},
    close(){
      this.$emit('update:show',false);
    },
		async doSubmit2(params){
			var url = '/api/v1/awardWork/manage/update';
			// 检测内容
			if(this.isEdit){
				params.id = this.formData.id;
			} else {
				url = '/api/v1/awardWork/manage/';
			}
			this.isLoading = true;
			params.content = JSON.stringify(params.content)
			var result = await this.request.post(url,params);
			
			this.isLoading = false;
			this.$message.success("保存成功");
			this.close();
		},
    async doSubmit(){

			var params = {content:this.$root.copy(this.formData.content)};
			if(!params.content.title){
				this.$message.error("作品名称不能为空");
				return;
			}
			if(!params.content.description){
				this.$message.error("作品描述不能为空");
				return;
			}
			
			if(this.$root.pk=='APD'){
				params.content.creators = params.content.creators.filter(item=>item.value);
				params.content.images = this.$refs.upload_images.getList() || [];
				
				if(!this.isShowVideo){
					params.content.videos = [];
				} else {
					params.content.videos = this.$refs.upload_videos.getList();
				}
				
			}
			
			if(params.content.awardType != this.data.awardType){
				params.awardType = params.content.awardType;
				this.$confirm('更改 作品类别 将更改 作品编号,确认保存吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// console.log(params);
					this.doSubmit2(params)
				}).catch(() => {
					
				});
			
			} else {
				this.doSubmit2(params)
			}
				
			
			
			
			
			
			
    }
  },
}
</script>

<style scoped lang="less">
.info-box{
	padding:10px 20px 20px;
}
#edit-content{
	width:90%;
}
.label-box{
	margin-left:10px;
	.label{
		display:inline-block;
		// width:80px;
		// text-align: right;
		line-height: 2;
	}
}
.creators_prepend{
	&>div{
		width:100%;
		text-align: center;
		&:first-child{
			width:40px;
		}
		&:last-child{
			text-align: left;
			width:70px;
		}
	}
}
</style>
