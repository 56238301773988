<template>
<div>
  <el-drawer
    :visible.sync="show"
    direction="ltr"
    size="45%"
    :withHeader="false"
    :close-on-press-escape="false"
    :before-close="close">
    <div class="mt-10"></div>
      <el-divider content-position="left">编委分组</el-divider>
			<el-form :model="formData" label-width="120px" size="small">
				<el-form-item label="编委A组" v-if="formData.groupTypeA">
					<el-checkbox-group v-model="formData.groupTypeA">
						<el-checkbox :label="item.id" :key="item.id" v-for="item in $root.awardTypeList">{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="编委B组" v-if="formData.groupTypeB">
					<el-checkbox-group v-model="formData.groupTypeB">
						<el-checkbox :label="item.id" :key="item.id" v-for="item in $root.awardTypeList">{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="编委C组" v-if="formData.groupTypeC">
					<el-checkbox-group v-model="formData.groupTypeC">
						<el-checkbox :label="item.id" :key="item.id" v-for="item in $root.awardTypeList">{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				
				<el-divider></el-divider>
				<el-form-item label="">
				  <el-button type="primary" @click="doSubmit" :loading="isLoading">保 存</el-button>
				</el-form-item>
			</el-form>
  </el-drawer>

</div>

</template>

<script>
const ZDID = 'CONFIG';
export default {
  name: 'GroupTypeEdit',
  components: {
    
  },
  props: {
    data: Object,
    show: Boolean,
		isEdit: Boolean
  },
  data(){
    return {
      isLoading:false,
			formData:{}
    }
  },
	mounted(){
		
	},
  watch: {
		show(newData){
			
			if(newData){
				this.$nextTick(()=>{
					this.initData()
				})
				
				// this.request.get("/api/v1/frontDeskUser/get?id="+this.data.id)
			}
		}
  },
	
  methods:{
		
		async initData(){
			let formData = await this.request.zd.get(ZDID);
			if(!formData.groupTypeA){
				formData.groupTypeA = []
			}
			if(!formData.groupTypeB){
				formData.groupTypeB = []
			}
			if(!formData.groupTypeC){
				formData.groupTypeC = []
			}
			console.log(formData);
			this.$set(this,'formData',formData);
			
			
		},
		async doSubmit(){
			this.loading = true;
			var params = this.$root.copy(this.formData);
			params.groupTypeA = params.groupTypeA.sort();
			params.groupTypeB = params.groupTypeB.sort();
			params.groupTypeC = params.groupTypeC.sort();
			await this.request.zd.update(ZDID,params);
			this.$root[ZDID] = params;
			this.loading = false;
			this.$message.success("保存成功");
			this.close();
			
		},
		close(){
		  this.$emit('update:show',false);
		},
		
  },
}
</script>

<style scoped lang="less">
.info-box{
	padding:10px 20px 20px;
}
#edit-content{
	width:90%;
}
.label-box{
	margin-left:10px;
	.label{
		display:inline-block;
		// width:80px;
		// text-align: right;
		line-height: 2;
	}
}
.studentCards{
	margin-top:10px;
	.el-image{
		margin-left:10px;
	}
}
</style>
