<template>
<div>
  <el-dialog
    title="文件上传"
    :visible.sync="show"
    width="600px"
    :before-close="close">
    <div>
			<el-form :model="formData" label-width="80px" size="small">
				<el-form-item label="文件">
					<div class="cover-uploader">
						<input type="file" ref="file" @change="doFileUpload"/>
						<el-input
							type="textarea"
						  v-model="formData.path" 
							placeholder="上传成功后会有地址在此处显示, 可拷贝放置文章内容链接内"
						  autocomplete="off" 
						  style="width:90%;"
							rows="5"
							:disabled="loading"
						>
						</el-input>
					</div>
				</el-form-item>
			</el-form>
		</div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">关闭</el-button>
    </span>
  </el-dialog>

</div>

</template>

<script>

export default {
  name: 'FileUpload',
  components: {
    
  },
  props: {
    show: Boolean,
  },
  data(){
    return {
      formData:{
				path:""
			},
			loading:false
    }
  },
	mounted(){
		
	},
  watch: {
		show(){
			if(this.show){
				this.formData.path = "";
				if(this.$refs.file) this.$refs.file.value = null;
			}
		}
  },
	
  methods:{
		doFileUpload(){
			this.loading = true;
			this.formData.path="文件上传中.."
			this.doUploadFile(this.$refs.file.files[0],'mgr/file/',(err,etag,uploadPathWithHost)=>{
				this.loading = false;
				this.formData.path=""
				if(err){this.$message.error("上传失败,请重新上传");return;}
				this.formData.path = uploadPathWithHost;
			});
		},
		doUploadFile(file,uploadPath,cb){
			this.$root.doUploadFile(file,{
				uploadPath
			},cb);
			
		},
    close(){
      this.$emit('update:show',false);
    },
  },
}
</script>

<style scoped lang="less">
.info-box{
	padding:10px 20px 20px;
}
#edit-content{
	width:90%;
}
.label-box{
	margin-left:10px;
	.label{
		display:inline-block;
		// width:80px;
		// text-align: right;
		line-height: 2;
	}
}

</style>
