<template>
  <div id="Home-Awawrd-Intro">
    <el-container style="height: 100%; border: 1px solid #eee; ">
      <el-aside width="200px" style="background-color: rgb(238, 241, 246);padding-bottom:20px;">
				<div class="list">
					<div @click="doSelectItem(index,item)" class="item" :class="{active:index==currentIndex}" v-for="(item,index) in formData.list" v-bind:key="item.title">
						<i class="el-icon-d-arrow-right" v-if="index==currentIndex"></i> {{item.title}}
					</div>
					<div @click="doAdd" class="plus">
						<el-button icon="el-icon-plus"></el-button>
					</div>
				</div>
			</el-aside>
			<el-main>
				
				<div v-if="currentIndex>-1 && formData.list[currentIndex]">
					<el-form :model="formData.list[currentIndex]" label-width="120px" size="small">
						<el-form-item label="栏目标题(中文)">
						  <el-input
						    v-model="formData.list[currentIndex].title" 
						    autocomplete="off" 
						    style="width:80%"
						  ></el-input>
						</el-form-item>
					
						<el-form-item label="主体内容(中文)">
							<div id="editor"></div>
						</el-form-item>
					
					
						<el-form-item label="栏目标题(英文)">
							<el-input
								v-model="formData.list[currentIndex].etitle" 
								autocomplete="off" 
								style="width:80%"
							></el-input>
						</el-form-item>
						<el-form-item label="主体内容(英文)">
							<div id="eeditor"></div>
						</el-form-item>
						<el-form-item label="">
						  <el-button type="primary" @click="doSave" :loading="isLoading">保 存</el-button>
						  <el-button type="danger" @click="doDelete" :loading="isLoading" v-if="!formData.list[currentIndex].isNew">移 除</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-main>
		</el-container>
  </div>
</template>

<script>
import E from "wangeditor"
const ZDID = 'INTRO';
export default {
  name: 'Home-Awawrd-Intro',
  components: {
    
  },
  data(){
    return {
			editor:null,
			eeditor:null,
			isLoading:false,
      formData:{
				list:[]
			},
			currentIndex:-1
    };
  },
  async mounted () {
		// await this.request.zd.create('INTRO',[
		// 			{title:"年鉴简介",etitle:"APD INTRODUCTION",content:"<b>123</b>",econtent:"<p>1231233</p>"},
		// 			{title:"指南下载",etitle:"SUBMISSION GUIDE",content:"<p>1231233</p>",econtent:""},
		// 			{title:"报名日期",etitle:"",content:"",econtent:""},
		// 			{title:"报名资格",etitle:"",content:"",econtent:""},
		// 			{title:"收录标准",etitle:"",content:"",econtent:""},
		// 			{title:"作品类别",etitle:"",content:"",econtent:""},
		// 			{title:"年度主题",etitle:"",content:"",econtent:""},
		// 			{title:"单元设置",etitle:"",content:"",econtent:""},
		// 			{title:"条例细则",etitle:"",content:"",econtent:""},
		// 			{title:"合作支持",etitle:"",content:"",econtent:""},
		// 			{title:"联系咨询",etitle:"",content:"",econtent:""},
		// 		]);
		this.formData.list = await this.request.zd.get(ZDID)||[];
    if(this.formData.list && this.formData.list.length){
			this.currentIndex = 0;
			this.initEditor('editor',this.formData.list[0].content);
			this.initEditor('eeditor',this.formData.list[0].econtent);
			
		}
  },
  methods: {
		initEditor(editor_name,content){

			this.$nextTick(()=>{
				if(!this[editor_name]){
					this[editor_name] = new E("#"+editor_name);
					this[editor_name].config.excludeMenus = [
						'emoticon',
						'video',
						'todo',
						// 'table',
						'quote',
						'code',
					];
					this[editor_name].config.height = 200;
					this[editor_name].config.lineHeights = ['1.2','1.3','1.4','1.5','1.75','2', '2.5', '3']
					this.editor.config.customUploadImg =  (resultFiles, insertImgFn)=> {
					    // resultFiles 是 input 中选中的文件列表
					    // insertImgFn 是获取图片 url 后，插入到编辑器的方法
							this.doUploadFile(resultFiles[0],'intro/',(err,etag,uploadPathWithHost)=>{
								if(err){this.$message.error("上传失败,请重新上传");return;}
								insertImgFn(uploadPathWithHost)
							});
					    // 上传图片，返回结果，将图片插入到编辑器中
					    // insertImgFn(imgUrl)
					}
					
					this[editor_name].create()
				}
				this.setHtml(this[editor_name],content);
				
				
			})
			
			
		},
		doUploadFile(file,uploadPath,cb){
			this.$root.doUploadFile(file,{
				uploadPath
			},cb);
			
		},
		setHtml(editor,content){
			editor.txt.html(content);
		},
    doSelectItem(index,item){
			if(this.currentIndex == -1){ this.currentIndex = index;}
			if(typeof this.formData.list[this.currentIndex].isNew !== 'undefined'){
				this.showSaveMessage(()=>{
					this.doSelectItem(index,item)
				});
				return;
			}
			
			this.currentIndex = index;
			if(!this.editor){
				this.initEditor('editor',this.formData.list[index].content);
				this.initEditor('eeditor',this.formData.list[index].econtent);
			}
			this.$nextTick(()=>{
				this.setHtml(this.editor,item.content);
				this.setHtml(this.eeditor,item.econtent);
			});
			
		},
		doAdd(){
			if(this.formData.list[this.currentIndex].isNew){
				this.showSaveMessage(this.doAdd);
				return;
			}
			var item = {
				title:"未命名",
				etitle:"undefined",
				content:"<p>编辑中..</p>",
				econtent:"<p>pedding..</p>",
				isNew:true
			};
			this.formData.list.push(item);
			this.doSelectItem(this.formData.list.length-1,item);
		},
		showSaveMessage(next){
			this.$confirm('当前内容暂未保存, 是否保存?', '提示', {
				confirmButtonText: '保存内容',
				cancelButtonText: '放弃内容',
				type: 'warning'
			}).then(() => {
				this.doSubmit();
			}).catch(() => {
				this.formData.list.splice(this.currentIndex,1);
				this.currentIndex = this.formData.list.length-1;
				next && next();
			});
		},
		doSave(){
			this.formData.list[this.currentIndex].content = this.editor.txt.html();
			this.formData.list[this.currentIndex].econtent = this.eeditor.txt.html();
			this.doSubmit();
		},
		async doSubmit(){
			this.isLoading = true;
			this.formData.list.forEach(a=>{
				delete a.isNew;
			})
			
			await this.request.zd.update(ZDID,this.formData.list);
			this.isLoading = false;
			this.$message({
				type: 'success',
				message: '保存成功!'
			});
		},
		async doDelete(){
			
			this.$confirm('删除后不能恢复, 确认是否删除?', '提示', {
				confirmButtonText: '确认删除',
				cancelButtonText: '取消操作',
				type: 'warning'
			}).then(async () => {
				var targetIndex = this.currentIndex;
				this.currentIndex = this.formData.list.length-2;
				var nextIndex = this.currentIndex;
				
				this.formData.list.splice(targetIndex,1);
				await this.doSubmit();
				this.doSelectItem(nextIndex,this.formData.list[nextIndex]);
				
				
				
				
			}).catch(() => {
				
			});
			
		}
  }
}
</script>

<style scoped lang="less">
.list{
	margin-top:20px;
	.item{
		font-size:16px;
		margin:5px 10px 10px 20px;
		line-height: 2;
		cursor: pointer;
		&>i{font-size:12px;vertical-align: middle;}
		&.active{
			cursor: default;
			font-weight: bold;
			// border-bottom:2px solid #2c3e50;
			// margin:5px 10px 8px 20px;
		}
	}
	.plus{
		text-align: center;
		/deep/.el-button{
			width:90%;
			margin:0 auto;
		}
	}
}
</style>