<template>
<div>
  <el-drawer
    :visible.sync="show"
    direction="rtl"
    size="70%"
    :withHeader="false"
    :close-on-press-escape="false"
    :before-close="close">
    <div class="mt-10"></div>
      <el-divider content-position="left">基本内容</el-divider>
			
      <el-form :model="formData" label-width="120px" size="small">
				
				<el-form-item label="文章封面">
					<div class="cover-uploader">
						<img v-if="formData.cover" :src="formData.cover" />
						<input type="file" ref="cover" @change="doCoverUpload"/>
					</div>
					<!-- <el-upload
						class="cover-uploader"
						action=""
						ref="cover"
						:http-request="doUpload"
						:show-file-list="false">
						<img v-if="formData.coverUrl" :src="formData.coverUrl" class="cover">
						<i v-else class="el-icon-plus cover-uploader-icon"></i>
					</el-upload> -->
				</el-form-item>
				<!-- <el-form-item label="所属赛事">
					<el-select v-model="formData.mainAwrad" placeholder="请选择">
						<el-option label="2021" value="2021"></el-option>
						<el-option label="2022" value="2022"></el-option>
					</el-select>
				</el-form-item> -->
				
        <el-form-item label="文章标题(中)">
          <el-input
            v-model="formData.title" 
						placeholder="请输入文章标题(中)"
            autocomplete="off" 
            style="width:80%"
          >
					</el-input>
        </el-form-item>
				<el-form-item label="文章标题(英)" v-if="$root.pk == 'APD'">
				  <el-input
				    v-model="formData.etitle" 
						placeholder="请输入文章标题(英)"
				    autocomplete="off" 
				    style="width:80%"
				  >
					</el-input>
				</el-form-item>
				<el-form-item label="标签" v-if="$root.pk == 'APD'">
				  <el-input
				    v-model="formData.keywords" 
						placeholder="请输入文章标签,选填"
				    autocomplete="off" 
				    style="width:80%"
				  >
					</el-input>
				</el-form-item>
				<el-form-item label="是否外链文章">
					<el-radio-group v-model="formData.isLink">
						<el-radio-button :label="true">是</el-radio-button>
						<el-radio-button :label="false">否</el-radio-button>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="外链地址" v-show="formData.isLink">
					<el-input
						v-model="formData.linkUrl" 
						placeholder="请输入 http:// 或 https:// 开头的URL地址"
						autocomplete="off" 
						style="width:80%;"
					></el-input>
				</el-form-item>
				<el-form-item label="文章内容" v-show="!formData.isLink">
          <div id="edit-content"></div>
        </el-form-item>
        <el-form-item label="排序序号">
          <el-input-number
          	v-model="formData.listorder" 
          	placeholder="请输入排序数字"
          	autocomplete="off" 
          	style="width:200px;"
          ></el-input-number>
					<el-alert
						title="注: 数字越高越靠前"
						type="info"
						left
						show-icon
						:closable="false"
						style="margin-top:10px;width:200px">
					</el-alert>
        </el-form-item>
				
				<el-form-item label="状态">
					<el-select v-model="formData.status" placeholder="请选择">
						<el-option label="启用" :value="1"></el-option>
						<el-option label="停用" :value="0"></el-option>
					</el-select>
				</el-form-item>

        <el-form-item label="">
          <el-button type="primary" @click="doSubmit" :loading="isLoading">保 存</el-button>
        </el-form-item>

      </el-form>

  </el-drawer>

</div>

</template>

<script>
import E from "wangeditor"
var md5 = require("blueimp-md5")

const defaultData = {
	id:"",
	cover:"",
	title:"",etitle:"",
	mainAwrad:"",
	content:"",econtent:"",
	isLink:false,
	linkUrl:"http://",
	listorder:0,
	createAt:"",
	keywords:"",
	status:1
};
export default {
  name: 'NewsEdit',
  components: {
    
  },
  props: {
    data: Object,
    show: Boolean,
		isEdit: Boolean
  },
  data(){
    return {
      formData:{...defaultData},
			isLoading:false,
			editor:null,
			ossConfig:{}
    }
  },
	mounted(){
		// this.initOSS();
		
	},
  watch: {
		show(newData){
			if(!this.editor){
				this.initEditor();
			}
			if(newData){
				this.$nextTick(()=>{
					this.initData()
				})
				
			}
		}
  },
	
  methods:{
		initEditor(){
			this.$nextTick(()=>{
				this.editor = new E("#edit-content")
				this.editor.config.excludeMenus = [
					'emoticon',
					'video',
					'todo',
					'table',
					'quote',
					'code',
				]
				this.editor.config.height = 150
				this.editor.config.lineHeights = ['1.2','1.3','1.4','1.5','1.75','2', '2.5', '3']
				this.editor.config.customUploadImg =  (resultFiles, insertImgFn)=> {
					this.doUploadFile(resultFiles[0],'news/content/',(err,etag,uploadPathWithHost)=>{
						if(err){this.$message.error("上传失败,请重新上传");return;}
						insertImgFn(uploadPathWithHost)
					});
				}
				// this.editor.config.customUploadVideo =  (resultFiles, insertVideoFn)=> {
				// 	this.doUploadFile(resultFiles[0],'news/content/',(err,etag,uploadPathWithHost)=>{
				// 		if(err){this.$message.error("上传失败,请重新上传");return;}
				// 		insertVideoFn(uploadPathWithHost)
				// 	});
				// }

				this.editor.create()
			})
		},
		initData(){
			var data = this.$root.copy(defaultData);
			if(this.data && this.data.id){
				this.isEdit = true;
				data = {
					...data,
					...this.$root.copy(this.data)
				};
				this.getContent(data.id)
			} else {
				console.log('editor',this.editor);
				this.editor.txt.html("<p></p>");
			}
			
			console.log('data',data);
			
			this.$set(this,'formData',data);
			console.log('this.formData',this.formData);
			
		},
		doCoverUpload(){
			this.doUploadFile(this.$refs.cover.files[0],'news/cover/',(err,etag,uploadPathWithHost)=>{
				if(err){this.$message.error("上传失败,请重新上传");return;}
				this.formData.cover = uploadPathWithHost;
			});
		},
		doUploadFile(file,uploadPath,cb){
			this.$root.doUploadFile(file,{
				uploadPath
			},cb);
			
		},
		async getContent(id){
			var data = await this.request.zd.get("NEWS_"+id);
			if(data && data.content){
				this.formData.content = data.content;
				this.editor.txt.html(this.formData.content);
			}
		},
    close(){
      this.$emit('update:show',false);
    },
		fixParams(){
			var params = this.$root.copy(this.formData)
			params.content && (delete params.content);
			params.econtent && (delete params.econtent);
			// params.etitle && (delete params.etitle);
			return params;
		},
    async doSubmit(){

			// 检测内容
			if(!this.formData.title){
				this.$message.error("标题不能为空!");
				return ;
			}
			if(this.formData.isLink && (!this.formData.linkUrl || this.formData.linkUrl == 'http://')){
				this.$message.error("外链地址不能为空!");
				return ;
			}
			
			this.isLoading = true;
			this.formData.content = this.editor.txt.html();
			var result = await this.request.zd.get('NEWS');
			var list = result? (result.list||[]) : [];
			
			var params;
			if(!this.formData.id){
				this.formData.createAt = Date.now();
				this.formData.id = md5(this.formData.createAt);
				await this.request.zd.create('NEWS_'+this.formData.id,this.$root.copy(this.formData));
				params = this.fixParams();
				list.push({...params});
			} else {
				await this.request.zd.update('NEWS_'+this.formData.id,this.$root.copy(this.formData));
				var index = list.findIndex(item=>item.id == this.formData.id);
				params = this.fixParams();
				list[index] = {...list[index],...params};
			}
			
			list.sort((a,b)=>{
				return b.listorder - a.listorder
			});
			console.log(JSON.stringify(list))
			await this.request.zd.update('NEWS',{total:list.length,list});
			
			
      this.isLoading = false;
			this.$message.success("保存成功");
			this.close();
			
    }
  },
}
</script>

<style scoped lang="less">
.cover-uploader{
	img {
		width:160px;
		max-height:400px;
		margin-bottom:10px;
		display: block;
	}
}
#edit-content{
	width:90%;
}
</style>
