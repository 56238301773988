<template>
  <div>

    <div class="search-box mt-10" >
      <el-form :inline="true" :model="formData" class="user-form-inline" size="mini">
				<div class="flex between">
					<div>
						<div>
							<el-form-item label="赛期">
								<el-select clearable @clear="doReset" @change="doSearch"
									v-model="formData.awardStage" 
									placeholder="请选择" 
									style="width:100px">
									<el-option label="全部" :value="0"></el-option>
									<el-option :label="item.name" :value="item.id" v-for="item in $root.awardStageList" :key="item.id"></el-option>
								</el-select>
							</el-form-item>
							
							
							<el-form-item label="类别">
								<el-select clearable @clear="doReset" @change="doSearch"
									v-model="formData.awardType"
									placeholder="请选择" 
									style="width:120px">
									<el-option label="全部" :value="0"></el-option>
									<el-option :label="item.name" :value="item.id" v-for="item in $root.awardTypeList" :key="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="状态">
								<el-select clearable @clear="doReset" @change="doSearch"
									v-model="formData.awardWorkStatus"
									placeholder="请选择" 
									style="width:120px">
									<el-option label="全部" :value="0"></el-option>
									<el-option :label="item.aname" :value="item.status" v-for="item in $root.awardWorkStatusList" :key="item.status"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="奖项">
								<el-select clearable @clear="doReset" @change="doSearch"
									v-model="formData.groupType"
									placeholder="请选择" 
									style="width:150px">
									<el-option label="全部" :value="0"></el-option>
									<el-option :label="item.aname" :value="item.status" v-for="item in $root.groupTypeList" :key="item.status"></el-option>
								</el-select>
							</el-form-item>
							
							<el-form-item label="组别" v-if="$root.pk=='EPA'">
								<el-select clearable @clear="doReset" @change="doSearch"
									v-model="formData.unitType"
									placeholder="请选择" 
									style="width:120px">
									<el-option label="全部" value=""></el-option>
									<el-option label="非专业组" :value="1"></el-option>
									<el-option label="专业组" :value="2"></el-option>
								</el-select>
							</el-form-item>
							
						</div>
						<div>
						<el-form-item label="内容关键词">
							<el-input 
							v-model="formData.keywords"
							clearable :readonly="isSearching"
							placeholder="内容关键词" 
							style="width:120px"
							@keyup.enter.native="doSearch"
							@clear="doReset"
							@blur="doBlur"></el-input>
						</el-form-item>
						<el-form-item label="作品编号">
							<el-input 
							v-model="formData.wid"
							clearable :readonly="isSearching"
							placeholder="作品编号" 
							style="width:120px"
							@keyup.enter.native="doSearch"
							@clear="doReset"
							@blur="doBlur"></el-input>
						</el-form-item>
						<el-form-item label="投稿者编号">
							<el-input 
							v-model="formData.uid"
							clearable :readonly="isSearching"
							placeholder="投稿者编号" 
							style="width:120px"
							@keyup.enter.native="doSearch"
							@clear="doReset"
							@blur="doBlur"></el-input>
						</el-form-item>
						<el-form-item label="日期">
							<!-- v-model="value2" -->
							<el-date-picker
						      v-model="formData.createDate"
									clearable @clear="doReset" @change="doSearch"
						      type="daterange"
						      align="right"
						      unlink-panels
						      range-separator="至"
						      start-placeholder="开始日期"
						      end-placeholder="结束日期"
									value-format="yyyy-MM-dd"
						      :picker-options="pickerOptions">
						    </el-date-picker>
						</el-form-item>
								
						</div>
					</div>
					<div>
						<el-form-item style="width:120px;">
							<el-button type="primary" icon="el-icon-search" @click="doSearch" :loading="isSearching">查询</el-button>
							<div class="mt-10"></div>
							<el-button type="success" icon="el-icon-download" @click="doExport" :loading="isExporting">导出数据包</el-button>
							<div class="mt-5"></div>
							<el-button type="success" size="mini" icon="el-icon-s-data" @click="showExcelViewer=true" >导出报表</el-button>
							
						</el-form-item>
						
						
						
					</div>
				</div>
      </el-form>

    </div>
    <el-divider></el-divider>
    <div>
      <el-table
        :data="list"
        v-loading="isSearching"
        style="width: 100%"
        row-key="id"
        border
        size="mini">
				<el-table-column
					prop="id"
					label="作品编号"
					width="100"
					align="center"
				>
					<template slot-scope="props">
						{{$root.createWID(props.row)}}
					</template>
				
				</el-table-column>
				<el-table-column
					prop="awardTypeName"
					label="参赛类别"
					width="86"
					align="center"
				>
					<template slot-scope="props">
						{{props.row.awardTypeName}}
					</template>
				
				</el-table-column>
				<el-table-column
					prop="unitType"
					label="参赛组别"
					width="86"
					align="center"
					v-if="$root.pk == 'EPA'"
				>
					<template slot-scope="props">
						{{props.row.unitType == 1 ? '非专业组':'专业组'}}
					</template>
				
				</el-table-column>
        <el-table-column
          prop=""
          label="作品名称"
        >
					<template slot-scope="props" v-if="props.row.content">
						<div class="flex middle">
							<div class="shrink" style="width:65px;" v-if="props.row.content.images && props.row.content.images.length">
								<CoverWrap :cavans="$root.pk=='EPA'" :src="props.row.content.images[0].path+'?x-oss-process=image/resize,l_100'" width="60px" height="36px"/>
							</div>
							<div style="overflow:hidden">
								<p v-if="$root.pk == 'EPA'">当前网络总票数：{{props.row.voteCount}}</p>
								<template v-if="props.row.content.title"><p class="one-line">{{props.row.content.title}}</p></template>
								<template v-if="props.row.content.etitle"><p class="one-line">{{props.row.content.etitle}}</p></template>
							</div>
						</div>
						
					</template>
        </el-table-column>
				
				<el-table-column
					prop="id"
					label="投稿者编号"
					width="100"
					align="center"
				>
					<template slot-scope="props" >
						<el-link type="warning" @click="showUserById(props.row.userId)">{{$root.createUID(props.row.userId)}}</el-link>
					
					</template>
				</el-table-column>
				
				<el-table-column
          prop="createTime"
          label="投稿/最后更新时间"
          width="140"
          align="center"
        >
					<template slot-scope="props">
						{{props.row.createTime}}<br/>
						{{props.row.updateTime}}
					<!-- {{$root.format('yyyy-MM-dd HH:mm',new Date(props.row.createTime))}} -->
					</template>
				</el-table-column>
        <el-table-column
          prop="status"
          label="状态 & 奖项"
          width="170"
          align="center"
        >
           <template slot-scope="props">
             <el-select placeholder="请选择" v-model="props.row.awardWorkStatus" size="mini" @change="doAwardWorkStatusChange(props.row)">
							 <el-option
								 v-for="item in $root.awardWorkStatusList"
								 :key="item.status"
								 :label="item.aname"
								 :value="item.status">
							 </el-option>
						 </el-select>
						 <div class="mt-5"></div>
						 <el-select placeholder="请选择" v-model="props.row.groupType" size="mini" @change="doGroupTypeChange(props.row)">
							 <el-option label="未设奖项" :value="0"></el-option>
							 <el-option
								 v-for="item in $root.groupTypeList"
								 :key="item.status"
								 :label="item.aname"
								 :value="item.status">
							 </el-option>
						 </el-select>
           </template>
        </el-table-column>
        <el-table-column 
          label="操作" 
          width="150"
          align="center"
        >
          <template slot-scope="scope">
						<div class="flex between w100">
							<el-button
								size="mini"
								type="primary"
								@click="$root.doWorkView(scope.row)">查看</el-button>
							<el-button
								size="mini"
								type="danger"
								@click="doDelete(scope.$index, scope.row)">删除</el-button>
						</div>
						<div>
							<div class="mt-10"></div>
							<el-button
								size="mini"
								type="" style="width:100%;"
								@click="doEdit(scope.$index, scope.row)">编辑作品</el-button>
						</div>
          </template>
        </el-table-column>
      </el-table>

      <el-divider></el-divider>
      <div class="ta-r">
        <el-pagination
          background
          layout="total,  prev, pager, next, jumper"
          @current-change="doChangePage"
          :page-size="pagination.pageSize"
          :current-page="pagination.page"
          :total="total">
        </el-pagination>
      </div>

    </div>
		<WorkEdit :data="currentData" :show.sync="showFormEdit" :isEdit="isEdit"/>
		<UserEdit :data="currentUserData" :show.sync="showUserFormEdit" :isEdit="true"/>
		<ExcelViewer type="work" :show.sync="showExcelViewer"/>
  </div>
</template>

<script>
import UserEdit from '@/components/UserEdit';
import WorkEdit from '@/components/WorkEdit';
import ExcelViewer from '@/components/ExcelViewer';
var timer;
export default {
  name: 'Award-List',
  components: {
    WorkEdit,
		UserEdit,
		ExcelViewer
  },
  data(){
    
    return {
			pickerOptions: {
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						// start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '昨天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
						end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '本月',
					onClick:(picker) =>{
						const end = new Date();
						const start = new Date();
						start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
						
						let lastDay = new Date(end.getFullYear(), end.getMonth()+1, 0).getDate();
						end.setTime(new Date(end.getFullYear(), end.getMonth(), lastDay));
						picker.$emit('pick', [start, end]);
					}
				},{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			
      formData:{
        keywords:"",
				wid:"",
				uid:"",
				awardStage:this.$root.CONFIG.mainAward?this.$root.CONFIG.mainAward:0,
				awardType:0,
				groupType:0,
				awardWorkStatus:0,
				createDate:["",""],
				unitType:""
      },
			isEdit:false,
      pagination:{
        pageSize:10,
        page:1,
      },
      total:0,
      list:[],
			exportList:[],
      isSearching:false,
      isExporting:false,
      showFormEdit:false,
      currentData:{},
			showUserFormEdit:false,
			showExcelViewer:false,
			currentUserData:{},
    };
  },
  watch: {
    showFormEdit(newData){
      if(!newData){
        this.doSearch(false);
      }
    }
  },
  mounted () {
		// this.request.zd.create('NEWS',{total:0,list:[]});
    this.doSearch();
  },
  methods: {
		async doAwardWorkStatusChange(item){
			var targetStatus = this.$root.getAwardWorkStatus(item.awardWorkStatus);
			
			this.$confirm(`
				正在更改作品状态为: <b>${targetStatus.aname}</b>
				<br/>
				用户端将显示作品状态为: <b>${targetStatus.name}</b>
			`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString:true
			}).then(async () => {
				await this.request.post('/api/v1/awardWork/manage/update',{
					id:item.id,
					awardWorkStatus:item.awardWorkStatus
				});
				item._awardWorkStatus = item.awardWorkStatus;
				
				this.$message({
					type: 'success',
					message: '更改成功!'
				});
			}).catch(() => {
				item.awardWorkStatus = item._awardWorkStatus;
			});
			
			
		},
		async doGroupTypeChange(item){
			var targetStatus = this.$root.getGroupType(item.groupType);
			
			this.$confirm(`
				正在更改作品奖项为: <b>${targetStatus.aname}</b>
				<br/>
				用户端将显示作品奖项为: <b>${targetStatus.name}</b>
			`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString:true
			}).then(async () => {
				await this.request.post('/api/v1/awardWork/manage/update',{
					id:item.id,
					groupType:item.groupType
				});
				item._groupType = item.groupType;
				
				this.$message({
					type: 'success',
					message: '更改成功!'
				});
			}).catch(() => {
				item.groupType = item._groupType;
			});
			
			
		},
		doEdit(index,row){
			this.currentData = this.$root.copy(row);
			this.isEdit = true;
			this.showFormEdit = true;
		},
		async showUserById(id){
			var userData = await this.request.get('/api/v1/frontDeskUser/get?id='+id);
			if(userData && userData.id){
				try{
					userData.config = JSON.parse(userData.config)
				}catch(e){
					userData.config = {};
				}
				this.showUserFormEdit = true;
				this.$set(this,'currentUserData',userData);
			}
			
			
		},
    
    doDelete(index,row){
      this.$confirm('此操作将永久删除该作品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
				
        this.request.delete(`/api/v1/awardWork/manage/delete?id=${row.id}`).then(res=>{

          if(!res){return ;}
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.doSearch(false);
        })
        
      }).catch(() => {
                
      });
      
    },

    async getList(url){
      this.isSearching = true;
			
      this.request.get(url).then(res=>{
        this.isSearching = false;
        this.list = [];
        this.total = 0;
        this.pagination.page = 1;
        if(!res){return ;}
        var list = res.rows || [];
				list.forEach(item=>{
					item._awardWorkStatus = item.awardWorkStatus;
					item._groupType = item.groupType;
					try{
						item.content = JSON.parse(item.content);
					}catch(e){
						item.content = {};
					}
					this.$root.initAwardType(item);
					// let status = this.statusList.find(temp => temp.status == item.awardWorkStatus);
					// item.awardWorkStatusName = status ? status.name :"已入选";
				})
				this.list = list
				
        // this.list.map(d=>{
        //   d.resources.unshift({type:"IMAGE",url:d.thumbnailUrl});
        // })
        this.total = res.total;
        // this.total = 646;
        this.pagination.page = res.page;

      });
    },
		createSearchData(){
			var params = {};
			if(this.formData.keywords){
				params.keywords = this.formData.keywords;
			}
			if(this.formData.awardType){
				params.awardType = this.formData.awardType;
			}
			if(this.formData.awardStage){
				params.awardStage = this.formData.awardStage;
			}
			if(this.formData.awardWorkStatus){
				params.awardWorkStatus = this.formData.awardWorkStatus;
			}
			if(this.formData.groupType){
				params.groupType = this.formData.groupType;
			}
			if(this.formData.createDate && this.formData.createDate[0]){
				params.createDateAfter = this.formData.createDate[0];
			}
			if(this.formData.createDate && this.formData.createDate[1]){
				params.createDateBefore = this.formData.createDate[1];
			}
			if(this.formData.unitType){
				params.unitTypes = [this.formData.unitType]
			}
			if(this.formData.wid){
				if(this.formData.wid.length != 9){
					this.$message.warning("作品编号格式错误");
					return false;
				}
				
				var widOK = true;
				var wids = this.formData.wid.split("");
				var awardTypeCode = wids.shift();
				var target = this.$root.awardTypeList.find(item=>item.code == awardTypeCode);
				if(target){
					params.awardType = target.id;
				} else {
					this.$message.warning("匹配不到对应作品类别");
					return false;
				}
				
				var awardStage = parseInt(wids.shift() + wids.shift());
				params.awardStage = awardStage;
				
				var id = parseInt( wids.join("") );
				params.ids = [id];
				
			}
			if(this.formData.uid){
				var userId = this.formData.uid;
				if(this.$root.pk=='APD'){
					userId = parseInt(this.formData.uid.toLowerCase(),17)/10000;
				} else if(this.$root.pk=='EPA'){
					userId = parseInt(this.formData.uid);
					if(isNaN(userId)){
						this.$message.warning("请输入正确投稿者编号");
						return false;
					}
				}
				
				params.userIds = [userId];
			}
			return params;
		},
    doSearch(resetPage = true){
      // if(!this.formData.collectUserName){
      //   this.$message.warning("搜索内容不能为空");
      //   return ;
      // }
      
			var searchParams = this.createSearchData();
			if(!searchParams){return ;}
			var formData = {...this.pagination,...searchParams};
      if(resetPage){formData.page = 1;}
      var params = this.$root.object2urlparams(formData);


      this.getList(`/api/v1/awardWork/paging?${params}`);
      
    },
		async doExport(){
			this.$confirm('此操作将会在服务端进行打包压缩等操作,请留意在非高峰期间操作.', '提示', {
				confirmButtonText: '确定,导出',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				var searchParams = this.createSearchData();
				if(!searchParams){return ;}
				var formData = this.$root.copy(searchParams);
				var params = this.$root.object2urlparams(formData);
				this.isExporting = true;
				await this.request.get(`/api/v1/exportTask/exportAwardWork?${params}`);
				this.isExporting = false;
				this.$root.$emit('openExportList');
			}).catch(() => {
				        
			});
		},
    doChangePage(currentPage){
      var searchParams = this.createSearchData();
			if(!searchParams){return ;}
      var formData = {...this.pagination,...searchParams};
      formData.page = currentPage;
      var params = this.$root.object2urlparams(formData);
      this.getList(`/api/v1/awardWork/paging?${params}`);
    },
    doBlur(){
      // if(!this.formData.collectUserName){this.doReset();}
    },
    doReset(){
      this.list = [];
      this.total = 0;
      this.pagination.page = 1;
      this.doSearch();
      // this.formData.collectType = 'INS';
    },
  }
}
</script>
<style lang="less" scoped>
.user-form-inline{
  .search-box{
    .el-select .el-input{
      width:160px;
    }
  }
}
</style>