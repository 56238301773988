<template>
<div>
  <el-drawer
    :visible.sync="show"
    direction="rtl"
    size="70%"
    :withHeader="false"
    :close-on-press-escape="false"
    :before-close="close">
    <div class="mt-10"></div>
      <el-divider content-position="left">基本内容</el-divider>
			
      <el-form :model="formData" label-width="120px" size="small">
				<el-form-item label="ID(只读)">
				  <el-input
				  	v-model="formData.id" 
				  	autocomplete="off" 
				  	style="width:200px;"
						readonly
				  ></el-input>
				</el-form-item>
				
				<el-form-item label="名称(只读)">
				  <el-input
				    v-model="formData.name" 
						placeholder="请输入名称(中文)"
				    autocomplete="off" 
				    style="width:80%"
						readonly
				  >
					</el-input>
				</el-form-item>
				<el-form-item label="年鉴封面">
					<div class="cover-uploader">
						<img v-if="formData.cover" :src="formData.cover" />
						<input type="file" ref="cover" @change="doCoverUpload"/>
					</div>
					<!-- <el-upload
						class="cover-uploader"
						action=""
						ref="cover"
						:http-request="doUpload"
						:show-file-list="false">
						<img v-if="formData.coverUrl" :src="formData.coverUrl" class="cover">
						<i v-else class="el-icon-plus cover-uploader-icon"></i>
					</el-upload> -->
				</el-form-item>
				
				<el-form-item label="顾问编委页">
					<el-select v-model="formData.status_judge" placeholder="请选择">
						<el-option label="展示" :value="1"></el-option>
						<el-option label="展示但不链" :value="2"></el-option>
						<el-option label="隐藏" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="历届APD页">
					<el-select v-model="formData.status_apd" placeholder="请选择">
						<el-option label="展示" :value="1"></el-option>
						<el-option label="展示但不链" :value="2"></el-option>
						<el-option label="隐藏" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="首页">
					<el-select v-model="formData.status_index" placeholder="请选择">
						<el-option label="展示" :value="1"></el-option>
						<el-option label="隐藏" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="详情页">
					<el-select v-model="formData.status_content" placeholder="请选择">
						<el-option label="展示" :value="1"></el-option>
						<el-option label="隐藏" :value="0"></el-option>
					</el-select>
				</el-form-item> -->

        <el-form-item label="">
          <el-button type="primary" @click="doSubmit" :loading="isLoading">保 存</el-button>
        </el-form-item>

      </el-form>

  </el-drawer>

</div>

</template>

<script>
import E from "wangeditor"
var md5 = require("blueimp-md5")
const KEY = 'APD';
const defaultData = {
	id:"",
	cover:"",
	name:"",
	createAt:"",
	status_judge:0,
	status_apd:0,
	status_index:0,
	status_content:0,
};
export default {
  name: 'APD-Edit',
  components: {
    
  },
  props: {
    data: Object,
    show: Boolean,
		isEdit: Boolean
  },
  data(){
    return {
      formData:{...defaultData},
			isLoading:false,
			editor:null,
			ossConfig:{}
    }
  },
	mounted(){
		// this.initOSS();
		
	},
  watch: {
		show(newData){
			if(newData){
				this.$nextTick(()=>{
					this.initData()
				})
			}
		}
  },
	
  methods:{
		initEditor(){
			this.$nextTick(()=>{
				this.editor = new E("#edit-content")
				this.editor.config.excludeMenus = [
					'emoticon',
					'video',
					'todo',
					'table',
					'quote',
					'code',
				]
				this.editor.config.height = 150
				this.editor.config.customUploadImg =  (resultFiles, insertImgFn)=> {
				    // resultFiles 是 input 中选中的文件列表
				    // insertImgFn 是获取图片 url 后，插入到编辑器的方法
						this.doUploadFile(resultFiles[0],'history/content/',(err,etag,uploadPathWithHost)=>{
							if(err){this.$message.error("上传失败,请重新上传");return;}
							insertImgFn(uploadPathWithHost)
						});
				    // 上传图片，返回结果，将图片插入到编辑器中
				    // insertImgFn(imgUrl)
				}

				this.editor.create()
			})
		},
		initData(){
			var data = this.$root.copy(defaultData);
			
			if(this.data && this.data.id){
				this.isEdit = true;
				data = {
					...data,
					...this.$root.copy(this.data)
				};
				// this.getContent(data.id)
			} else {
				data = {
					id: this.$root.awardStageList.length?(this.$root.awardStageList[0].id + 1):1
				};
				data.name = "APD "+data.id;
			}
			
			console.log('data',{...this.formData,...data});
			
			this.$set(this,'formData',{...this.formData,...data});
			// console.log('this.formData',this.formData);
			
		},
		doCoverUpload(){
			this.doUploadFile(this.$refs.cover.files[0],'APD/cover/',(err,etag,uploadPathWithHost)=>{
				if(err){this.$message.error("上传失败,请重新上传");return;}
				this.formData.cover = uploadPathWithHost;
			});
		},
		doUploadFile(file,uploadPath,cb){
			this.$root.doUploadFile(file,{
				uploadPath
			},cb);
			
		},
		async getContent(id){
			var data = await this.request.zd.get(KEY+"_"+id);
			if(data && data.content){
				this.formData.content = data.content;
				this.editor.txt.html(this.formData.content);
			}
		},
    close(){
      this.$emit('update:show',false);
    },
		fixParams(){
			var params = this.$root.copy(this.formData)
			// params.content && (delete params.content);
			// params.econtent && (delete params.econtent);
			// params.etitle && (delete params.etitle);
			return params;
		},
    async doSubmit(){

			// 检测内容
			if(!this.formData.name){
				this.$message.error("名称不能为空!");
				return ;
			}
			this.isLoading = true;
			var list = this.$root.awardStageList||[];
			
			var params;
			if(!this.isEdit){
				this.formData.createAt = Date.now();
				await this.request.zd.create(KEY+'_'+this.formData.id,{
					id:this.formData.id,
					title:"",etitle:"",
					content:"",
					econtent:"",
				});
				params = this.fixParams();
				list.push({...params});
			} else {
				var index = list.findIndex(item=>item.id == this.formData.id);
				params = this.fixParams();
				list[index] = {...list[index],...params};
			}
			
			list.sort((a,b)=>{
				return b.id - a.id
			});
			console.log(JSON.stringify(list))
			await this.request.zd.update('awardStageList',list);
			
			
      this.isLoading = false;
			this.$message.success("保存成功");
			this.close();
			
    }
  },
}
</script>

<style scoped lang="less">
.cover-uploader{
	img {
		width:160px;
		max-height:400px;
		margin-bottom:10px;
		display: block;
	}
}
#edit-content{
	width:90%;
}
</style>
